<template>
  <div class="container-fluid">
    
            <div class="col-12 table-title">
              Nombre del curso: Metodologías para implementar la Norma ISO 9001:2015
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Conocer las metodologías para implementar la norma ISO 9001:2015, con la finalidad de optimizar recursos y fomentar la competitividad y sustentabilidad como impulsor de la mejora del desempeño de la organización.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El propósito del curso consistirá en que los participantes identifiquen los tipos de metodologías que existen para la implantación de un sistema de gestión de calidad en una organización en la Norma Internacional ISO 9001:2015 a efecto de que se aplique adecuadamente en la empresa donde trabaja para mejorar el modelo que se ocupa. 
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Directores, gerentes, coordinadores, jefes de área, auditores, consultores y cualquier persona que esté interesada en conocer las metodologías de aplicación e implementación de un sistema de gestión de calidad en la Norma Internacional ISO 9001:2015.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Requisitos de la norma ISO 9001: 2015.</li>
                  <li>Enfoque a proceso (ciclo planear-hacer-verificar-actuar).</li>
                  <li>Enfoque a riesgos.</li>
                  <li>Matriz de responsabilidades.</li>
                  <li>Interrelación con otros modelos de gestión.</li>
                  <li>Ejercicios prácticos de aplicación (mapa estratégico, matriz FODA, procesos, indicadores económicos, revisión directiva, entre otros).</li>
                  <li>Auditoría para el sistema de gestión de calidad.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>8 días 16 horas (2 horas por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 16 al 18 y del 22 al 26 de mayo de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  