<template>
  <div class="container-fluid">
    <h1 class="my-h1">Análisis de Causa Raíz y Acciones Correctivas</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Análisis de Causa Raíz y Acciones Correctivas</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Cuando ocurre una falla, se percibe a través de ciertas manifestaciones o síntomas, no la causa de la falla, la mayoría de las veces esto conduce a la acción sobre las consecuencias y no sobre la raíz del problema, de modo que la falla se repite una y otra vez.
      </p>
      <p>
        Permite identificar errores u omisiones en los sistemas utilizados hasta ese momento y que han sido insuficientes o ineficientes para evitar la aparición de un accidente y la búsqueda de soluciones para que estas, si es posible, no vuelvan a ocurrir.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Conoce como eliminar la causa inicial que está generando un problema para evitar que los problemas ya resueltos vuelvan a aparecer, con fin de eliminar su causa raíz. Aprehender las técnicas para localizar la falla en el proceso o sistema y la influencia de factores que fueron importantes en el incidente y hacer el análisis de causa raíz real.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Conceptos preliminares (falla, causa, modo de
            falla, consecuencia, efecto, síntoma).
          </li>
          <li>
            Herramientas cualitativas.
          </li>
          <li>
            Herramientas cuantitativas.
          </li>
          <li>
            Metodología de Análisis de causa raíz.
          </li>
          <li>
            Pasos en la ejecución del análisis causa raíz.
          </li>
          <li>
            Herramientas para la validación de causas del
            problema.
          </li>
          <li>
            Herramientas para la eliminación de las causas del
            problema.
          </li>
          <li>
            Acciones correctivas y seguimiento.
          </li>
          <li>
            Ciclo de mejoramiento continuo PHVA.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>