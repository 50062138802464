<template>
  <div class="container-fluid">
    <h1 class="my-h1">Liderazgo Creativo</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Liderazgo Creativo</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Las personas dentro de las organizaciones requieren de personas que sean guías que impulsen, controlen, ayuden, influencien en el comportamiento de los trabajadores para lograr los objetivos de la organización.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Para lograr que tu organización genere valor de forma sustentable, tener lideres o ser líder en tu organización es un nuevo espacio de aprendizaje y reflexión que te dará características y claves de equipos altamente creativos y de desarrollo de estrategias para fomentar trabajo innovador.
      </p>
      <p>
        Visión y estrategia ha diseñado este curso para las personas desempeñen papeles o funciones que impulsen o controlen el comportamiento de otros hacia el objetivo de la organización.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Creatividad e innovación como fenómeno sistémico.
          </li>
          <li>
            Entrenamiento de distintas habilidades cognitivas
            del pensamiento divergente; pensar estrategias
            para potenciar la generación de soluciones
            originales en los colaboradores del equipo.
          </li>
          <li>
            Metodologías de trabajo para conducir proyectos de
            innovación.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>