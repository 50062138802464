<template>
  <div class="container-fluid">
    <h2 class="my-h2">Servicio de implantación</h2>
    <h3 class="my-h3">Alcance</h3>
    <div class="general-text">
      <p>
        Nuestra intervención permitirá la certificación o el mantenimiento del Sistema de Gestión de la Calidad (SGC) en el proceso de operación del centro de trabajo, en el que se dará cumplimiento a los requisitos establecidos en la norma ISO 9001:2015, así mismo se permitirá reforzar los puntos observados durante la auditoría de certificación atendiendo y generando las acciones pertinentes con la finalidad de mejorar las actividades en los procesos del Sistema de Gestión de la Calidad y demostrando al mismo tiempo su madurez y mejora continua.
      </p>
    </div>
    <h3 class="my-h3">Fases</h3>
    <div class="general-text">
      <p>
        <ul>
          <li>Adecuación documental del Sistema de Gestión de Calidad.</li>
          <li>Capacitación del personal.</li>
          <li>Consultoría y acompañamiento ISO 9001:2015.</li>
          <li>Auditoría interna.</li>
          <li>Revisión por la Dirección.</li>
          <li>Preparación para la Auditoría de Certificación/Mantenimiento.</li>
        </ul>
      </p>
      <p>
        La propuesta del servicio considera en las acciones correctivas lo siguiente:
        <ul>
          <li>Determinación de las causas raíz de los hallazgos por auditoría interna o externa.</li> 
          <li>Generación de los planes de acción acordes a los hallazgos detectados.</li>
          <li>Seguimiento de los planes de acción.</li>
          <li>Análisis y evaluación de los resultados obtenidos de las acciones implementadas.</li>
          <li>Verificación de las acciones y su cumplimiento conforme a los tiempos propuestos. </li>
          <li>Validación y cierre de las acciones correctivas.</li>
        </ul>
      </p>
      <p>
        Es necesario verificar con los dueños de procesos y con el Responsable del Sistema de Gestión de Calidad la información documentada en los procesos, ya que las acciones correctivas no se pueden considerar aisladas.
      </p>
      <p>
        Con la finalidad de alcanzar los objetivos planteados de manera coordinada con el personal del centro de trabajo, realizaremos las siguientes actividades:
        <ul>
          <li>Seguimiento a las acciones correctivas derivadas de la auditoría de certificación.</li>
          <li>Adecuación del alcance en la documentación del Sistema de Gestión de Calidad en función de los resultados de la auditoría de certificación.</li>
          <li>Seguimiento y cierre de las no conformidades de auditoría externa, soportado con los registros correspondientes.</li>
          <li>Mantenimiento y refuerzo del control de la información documentada establecida en los procesos del Sistema de Gestión de Calidad considerando el contexto de la organización, partes interesadas, gestión del conocimiento, determinación de los documentos externos, así como la determinación de los requisitos legales aplicables a los procesos.</li>
          <li>Capacitar al personal del centro de trabajo.</li>
          <li>Coordinar el levantamiento del clima laboral al interior del centro de trabajo.</li>
          <li>Medición de los objetivos de calidad establecidos en el Sistema de Gestión de Calidad y de proceso en función de los resultados obtenidos.</li>
          <li>Planificar de manera coordinada con el personal responsable del centro de trabajo, la realización de una auditoría.</li>
          <li>Llevar a cabo la auditoría interna.</li>
          <li>Realizar el informe de auditoría interna.</li>
          <li>Acompañar al personal del centro de trabajo designado para el análisis de los hallazgos identificados, y a partir de ello, elaborar los planes de acción. </li>
          <li>Apoyar en la integración del Informe de Revisión por la Dirección conforme a lo establecido en la Norma ISO 9001:2015.</li>
          <li>Coordinar con el personal designado el desarrollo de la sesión de Revisión por la Dirección.</li>
          <li>Brindar acompañamiento y mantenimiento de los procesos con alcance del Sistema de Gestión de Calidad.</li>
        </ul>
      </p>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "ServicioDeImplantacionView",
};
</script>
        
<style>
</style>