<template>
  <div class="container-fluid">
    <h1 class="my-h1">Curso Auditor Interno ISO 9001</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Curso Auditor Interno ISO 9001</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        La Norma ISO 9001: 2015 se ha actualizado, incorpora cambios significativos en relación con la anterior versión del año 2008. Visión y Estratégia Consultoría ha diseñado un curso a medida de las necesidades de todo auditor interno.
      </p>
      <p>
        Conocer a detalle los nuevos requisitos y el enfoque sobre cómo abordarlos, ayudará a la ejecución de auditorías eficaces que ayuden a la conformidad del SG con ISO 9001:2015 y su eficacia continua.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            La incorporación del pensamiento basado en riesgos
            para la planificación del SGC, y la toma de
            acciones para afrontarlos.
          </li>
          <li>
            La necesidad de identificación de las “partes
            interesadas” pertinentes y sus requisitos, en
            relación con los productos y servicios que brinda
            una organización.
          </li>
          <li>
            El control de cambios en el SGC de manera de
            asegurar la integridad del mismo en todo momento.
          </li>
          <li>
            La necesidad de un mayor alineamiento entre el SGC
            y la estrategia de negocio de una organización y
            la necesidad del liderazgo por parte de la alta
            dirección.
          </li>
          <li>
            La ampliación del enfoque en la mejora continua,
            considerando otras iniciativas como la innovación,
            para ejecutar proyectos de mejora que fortalecen
            el SGC.
          </li>
          <li>
            La introducción del concepto de “información
            documentada”, requisitos aplicables a “documentos”
            y “registros”.
          </li>
        </ul>
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Estructura de sistemas de gestión
          </li>
          <li>
            Enfoque a procesos
          </li>
          <li>
            Proceso de pensamiento basado en riesgos
          </li>
          <li>
            Principios de calidad
          </li>
          <li>
            Procesos de certificación
          </li>
          <li>
            Planificación y realización de auditorias
          </li>
          <li>
            Procedimientos de evaluación
          </li>
          <li>
            Desarrollo de políticas
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>