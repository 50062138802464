<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Formación de auditor(a) líder en la Norma ISO 37001:2016
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Al final de este curso de capacitación, los participantes podrán:
                <ol>
                  <li>Explicar los principios y conceptos fundamentales de un Sistema de Gestión Antisoborno basado en ISO 37001.</li>
                  <li>Interpretar los requisitos ISO 37001 para un Sistema de Gestión Antisoborno desde la perspectiva de un auditor.</li>
                  <li>Evaluar la conformidad del Sistema de Gestión Antisoborno con los requisitos de ISO 37001, de acuerdo con los principios y conceptos fundamentales de auditoría.</li>
                  <li>Planificar, realizar y cerrar una auditoría de cumplimiento con ISO 37001, de acuerdo con los requisitos de ISO/IEC 17021-1, las directrices de ISO 19011 y otras prácticas recomendadas de auditoría.</li>
                  <li>Gestionar un programa de auditoría con base en ISO 37001.</li>
                </ol>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso permitirá a los asistentes adquirir la experiencia necesaria para realizar una auditoría del Sistema de Gestión Antisoborno mediante la aplicación de principios, procedimientos de auditoría ampliamente reconocidos.
              </p>
              <p>
                Con la capacitación los participantes serán capaces de dominar las técnicas de auditoría y llegar a ser alguien competente en la gestión de un programa de auditoría y de un equipo de auditoría.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Auditores que deseen realizar y liderar auditorías de certificación de un Sistema de Gestión Antisoborno, gerentes o consultores que quieran dominar un proceso de auditoría de un Sistema de Gestión Antisoborno Las personas encargadas de mantener la conformidad con la ISO 370001.Expertos técnicos que busquen prepararse para una auditoría del Sistema de Gestión Antisoborno, asesores expertos en gestión antisoborno.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Introducción a la gestión antisoborno.</li>
                  <li>Principios de auditoría, preparación e inicio de auditoría.</li>
                  <li>Auditorías en el sitio.</li>
                  <li>Cierre de la auditoría.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>2 días 6 horas (3 horas por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>1 y 2 de junio de 2023
            </div>

  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  