<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del Taller: Proceso de implantación en la Norma Oficial
            Mexicana NOM-035-STPS-2018, Factores de riesgos Psicosociales en el
            trabajo-identificación, análisis y prevención en centros de trabajo
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>Comprender los lineamientos de la norma, analizar y evaluar conforme los parámetros normativos establecidos, así como diseñar un plan de acción enfocado en la identificación y control de los factores de riesgo psicosocial, y en la promoción de un entorno organizacional favorable.</p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>El curso proporcionará a los participantes los conocimientos necesarios para que puedan desarrollar con éxito cada uno de los pasos del proceso para implantar la Norma Oficial Mexicana NOM-035-STPS-2018 factores de riesgos Psicosociales en el trabajo-identificación, análisis y prevención en su centro de trabajo.</p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Profesionistas, independientes, consultores, académicos, estudiantes, la alta dirección, integrantes de comités y personal de los centros de trabajo públicos, privados y sociales que estén interesados en implantar la Norma Oficial Mexicana NOM-035-STPS-2018 factores de riesgos Psicosociales en el trabajo-identificación, análisis y prevención
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ul class="ul-clean">
                  <li>
                    Unidad 1. ¿Por qué es necesaria la NOM-035-STPS-2018?
                  </li>
                  <ul class="ul-clean">
                    <li>
                      1.1 ¿Qué son los factores de riesgo psicosocial en el trabajo?
                    </li>
                    <li>
                      1.2 ¿Cómo se agrupan los factores de riesgo psicosocial?
                    </li>
                    <li>
                      1.3 ¿Cuál es el alcance y el objetivo de la NOM-035-STPS-2018?
                    </li>
                    <li>
                      1.4 ¿A quién le aplica la NOM-035-STPS-2018?
                    </li>
                  </ul>
                </ul>
                <ul class="ul-clean">
                  <li>
                    Unidad 2. Obligaciones generales del patrón y de los trabajadores
                  </li>
                  <ul class="ul-clean">
                    <li>
                      2.1 Obligaciones del patrón
                    </li>
                    <ul class="ul-clean">
                      <li>
                        2.1.1 Política de prevención de riesgos psicosociales
                      </li>
                      <li>
                        2.1.2 Identificar y analizar los factores de riesgo psicosocial y evaluar el entorno organizacional
                      </li>
                      <li>
                        2.1.3 Identificar a los trabajadores que fueron sujetos a acontecimientos traumáticos severos durante o con motivo del trabajo
                      </li>
                      <li>
                        2.1.4 Adoptar medidas de prevención y control
                      </li>
                      <li>
                        2.1.5 Difundir y proporcionar información a los trabajadores
                      </li>
                      <li>
                        2.1.6 Practicar exámenes médicos
                      </li>
                      <li>
                        2.1.7 Registros
                      </li>
                    </ul>
                  </ul>
                </ul>
                <ul class="ul-clean">
                  <li>
                    Unidad 3. Identificación y análisis de los factores de riesgo psicosocial y evaluación de los factores de riesgo psicosocial
                  </li>
                  <ul class="ul-clean">
                    <li>
                      3.1 ¿Que comprende la identificación y análisis de los factores de riesgo psicosocial?
                    </li>
                    <li>
                      3.2 Elementos que se deben evaluar como parte del entorno organizacional
                    </li>
                    <li>
                      3.3 Métodos para identificar y analizar los factores de riesgo psicosocial y evaluar el entorno organizacional
                    </li>
                    <li>
                      3.4 Informe de resultados de la evaluación
                    </li>
                  </ul>
                </ul>
                <ul class="ul-clean">
                  <li>
                    Unidad 4. Medidas de prevención y medidas de control
                  </li>
                  <ul class="ul-clean">
                    <li>
                      4.1 Medidas de prevención generales
                    </li>
                    <li>
                      4.2 Acciones y programas para la prevención específicas
                    </li>
                    <li>
                      4.3 Medidas de control: Programa para la atención y niveles de intervención
                    </li>
                  </ul>
                </ul>
                <ul class="ul-clean">
                  <li>
                    Unidad 5. ¿Cómo se evaluará el cumplimiento de la NOM-035-STPS-2018?
                  </li>
                  <ul class="ul-clean">
                    <li>
                      5.1 Unidades de verificación
                    </li>
                    <li>
                      5.2 Fecha de entrada en vigor
                    </li>
                    <li>
                      5.3 Sanciones administrativas
                    </li>
                    <li>
                      5.4 Información complementaria
                    </li>
                  </ul>
                </ul>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>10 días 30 horas (3 por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 8 al 12 y del 15 al 19 de mayo de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  