<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Requisitos, beneficios y ventajas al implantar las
            cartas de servicios en una organización de la administración
            pública, organismo autónomo, y entidad y empresa pública y privada.
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Conocer los requisitos, beneficios y ventajas que tiene el implementar las cartas de servicio en una organización administrativa pública, órgano autónomo, y entidades y empresas públicas y privadas.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                Al finalizar el curso, los participantes conocerán cuales son los requisitos, los beneficios y las ventajas que tiene para una organización de la administración pública, órganos autónomos, y entidades y empresas públicas y privadas implementar cartas de servicios.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>La alta dirección, jefes, coordinadores y personal involucrado en la implantación y certificación de las cartas de servicio en las organizaciones de la administración pública, órganos autónomos, entidades y empresas públicas y privadas.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>
                    <b>Requisitos de las cartas de servicios</b>
                  </li>
                  <ul>
                    <li>Identificación de los servicios</li>
                    <li>Identificar estándares de servicio y calidad</li>
                    <li>Forma de consulta de la legislación aplicable, de los derechos y obligaciones del usuario</li>
                    <li>Analizar la demanda y la satisfacción de los usuarios</li>
                    <li>Planificar e implantar medidas de mejora para subsanar y/o compensar los incumplimientos de los compromisos.</li>
                    <li>Determinar el grado de cumplimiento de los compromisos y periodicidad</li>
                  </ul>
                  <li>
                    <b>Beneficios de las cartas de servicios</b>
                  </li>
                  <ul>
                    <li>Para la organización</li>
                    <li>Ante usuarios</li>
                  </ul>
                  <li>
                    <b>Ventajas de la implantación de las cartas de servicios </b>
                  </li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>1 día 3 horas 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>30 de junio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  