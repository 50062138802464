<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Calidad en el servicio electoral
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Sensibilizar a todos los servidores electorales de la organización electoral referente a la importancia de calidad para la mejora de su trabajo, facilitándoles las técnicas necesarias para desarrollar el enfoque de procesos, “Definición, Análisis, Control, Optimización y Estandarización de los procesos, a través del conocimiento y dominio de las herramientas de eficiencia para solucionar los problemas de aptitud y mejorar el servicio. 
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso permitirá a los participantes comprender los conceptos básicos de la calidad en materia electoral con base en la NMX-CC-54001-IMNC-2020, a efecto de estos pueden brindar en el desempeño de sus funciones un servicio electoral eficiente y eficaz que avale el respeto a los principios de transparencia, participación, igualdad y legalidad, atendiendo y contribuyendo al fortalecimiento y expectativas que reclaman el grupo de interés, contando para ello con servidores comprometidos con la excelencia en la gestión e innovación a través del uso de herramientas tecnológicas y buenas prácticas que aseguran la mejora continua del Sistema de Gestión de la Calidad. 
              </p>
              <p>
                Para lo cual conocerán las ideas básicas, el enfoque, y sistemas hacia la calidad en materia electoral, el concepto de proceso, definición, análisis, control, optimización y estandarización de procesos. 
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>La alta dirección, jefes de área, y/o departamento, coordinadores y todo servidor electoral, así como encargado (s) de calidad o en preparación para este nivel de servicio y personal del órgano electoral, que este disponga.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Respondemos por la transferencia de conocimientos mediante una metodología que acceda a elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <b>PERSPECTIVA DE CALIDAD</b>
                <ol>
                  <li>Ideas básicas de la calidad;</li>
                  <li>Enfoque hacia la calidad; y</li>
                  <li>Sistemas de calidad en México e internacionales.</li>
                </ol>
                <b>VISIÓN DE PROCESOS</b>
                <ol>
                  <li>El concepto de un proceso ¿Qué es un proceso?;</li>
                  <li>Definición de procesos;</li>
                  <li>Análisis de procesos;</li>
                  <li>Control de procesos; y</li>
                  <li>Optimización y estandarización de los procesos.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>1 día 4 horas 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>31 de agosto de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  