<template>
  <div class="container-fluid">
    <h1 class="my-h1">Administración de Proyectos Seis Sigma</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Administración de Proyectos Seis Sigma</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Six Sigma es una metodología de proyectos de gestión que pone énfasis en la satisfacción de los clientes en cuanto a la calidad de producto. La función de esta metodología es eliminar aquellos problemas que puedan ocurrir en la fase de desarrollo del proyecto.
      </p>
      <p>
        Seis Sigma se entrenan y designan los líderes, quienes son los dueños de los proyectos críticos para la organización. Para desarrollar estos proyectos se escogen y preparan Expertos (conocidos como : Master Black Belt, Black Belt, Green Belt ), quienes se convierten en agentes de cambio para impulsar y desarrollar estos proyectos, en conjunto con los equipos de trabajo seleccionados para los mismos.
      </p>
      <p>
        Brindar a las empresas diversas herramientas que les permita mejorar la capacidad de sus procesos del negocio hasta lograr un desempeño perfecto, cero defectos.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Es una ventaja competitiva muy importante y admirada por empleadores y reclutadores. Esta habilidad puede ser implementada en muchos cargos y departamentos. Sin embargo, es más popular en los ámbitos de gestión que están relacionados con la producción, las operaciones y el análisis de negocios.
      </p>
      <p>
        Existen 5 fases del proceso de implementación de seis sigma: controlar, definir, medir, analizar, mejorar y controlar. Los proyectos six sigma son definitivamente proyectos dinámicos que ofrecen un millón de oportunidades de crecimiento. Aprende a reconocer los requisitos del cliente y el mejor plan de ataque para tus proyectos empresariales con edX. Estas cada vez mas cerca de obtener los conocimientos necesarios para una certificación lean six sigma.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Introducción a la metodología Six sigma.
          </li>
          <li>
            Fases de la mitología Six Sigma.
          </li>
          <li>
            La metodología Seis Sigma y su aplicación.
          </li>
          <li>
            Sistemas, procesos y liderazgo en la empresa.
          </li>
          <li>
            Desarrollo de la organización.
          </li>
          <li>
            Gestión de procesos de la empresa.
          </li>
          <li>
            Indicadores de desempeño.
          </li>
          <li>
            Selección y justificación financiera de proyectos.
          </li>
          <li>
            Planeación y control de proyectos.
          </li>
          <li>
            Alcance del proyecto.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>