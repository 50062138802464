<template>
  <div class="container-fluid">
    <h1 class="my-h1">Sistemas de Gestión de la Calidad</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Sistemas de Gestión de la Calidad</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Implementar un Sistema de Gestión de la Calidad basado en la Norma ISO 9001:2015 es una decisión estratégica que toman las organizaciones, y que implica planificar, asignar recursos y llevar a cabo una serie de actividades cuyo hito inicial suele ser lograr la certificación.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Visión y Estratégia Consultoría ha diseñado este curso poniendo el foco en introducir a los profesionales las ventajas y principios de la aplicación de Sistemas de Gestión, y lograr así un entendimiento de los pasos a seguir para la correcta implementación y lograr la certificación.
      </p>
      <p>
        <b>Aprende a:</b>
      </p>
      <p>
        Lograr de manera sistemática los resultados esperados en todos y cada uno de sus procesos.
      </p>
      <p>
        Planificar e implementar un SGC conociendo el contexto de la organización, sus partes interesadas, sus riesgos y oportunidades.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            La situación actual de las organizaciones en un
            mundo globalizado.
          </li>
          <li>
            Los 7 principios de la calidad.
          </li>
          <li>
            El enfoque a procesos, el pensamiento basado en el
            riesgo y el ciclo PHVA: fundamentos de ISO
            9001:2015.
          </li>
          <li>
            Objeto, estructura interpretación de sus
            requisitos de la norma 9001:2015.
          </li>
          <li>
            La implementación de un sistema de gestión de la
            calidad basado en la norma ISO 9001:2015.
          </li>
          <li>
            Los pasos a seguir de la certificación ISO 9001.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>