<template>
  <div class="container-fluid">
    <h1 class="my-h1">Planeación Estratégica y Control de Calidad</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Planeación Estratégica y Control de Calidad</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        La planeación estratégica se realiza dentro de la organización, establece metas correctas y medios correctos, optimiza antes de invertir, proyecta al futuro y posibilita tomar decisiones en torno al quehacer actual y al camino que deben recorrer para adecuarse a los cambios y a las demandas que les impone el entorno. Solo así se logrará la mayor calidad, eficiencia y eficacia en los bienes, servicios o productos que se proveen.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Para que nuestro servicio o producto sea capaz de ser útil al cliente, necesita ser de total calidad para ello necesitamos planear como instrumentar el servicio o producto que será ofrecido al cliente y lo pueda comprar.
      </p>
      <p>
        La aprobación del cliente va en función a la gestión que se ha dado al servicio y/o producto para una satisfacción, para triunfar la organización tiene que basarse en la participación de todos sus miembros en mejorar la calidad del proceso.
      </p>
      <p>
        Aprehende a utilizar eficientemente los recursos financieros y humanos para lograr calidad sin costos elevados.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Fundamentos de la planeación estratégica.
          </li>
          <li>
            Responsabilidades en la planeación estratégica.
          </li>
          <li>
            Determina los valores estratégicos de la
            organización.
          </li>
          <li>
            Generar la visión estratégica.
          </li>
          <li>
            Generación de indicadores clave.
          </li>
          <li>
            Planeamiento de la estrategia.
          </li>
          <li>
            De la planeación estratégica a la planeación
            operativa.
          </li>
          <li>
            La planeación estratégica como instrumento de la
            gestión por resultados.
          </li>
          <li>
            Conocer a los clientes.
          </li>
          <li>Diagnóstico interno.</li>
          <li>
            Procedimientos de calidad.
          </li>
          <li>
            La organización del trabajo y la calidad.
          </li>
          <li>
            Herramientas de la calidad y como aplicarlas.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>