<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Hablemos de la calidad de los árbitros electorales
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Hablemos de la calidad de los árbitros electorales</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/vote.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          “Él puede ganar las elecciones, pero, ¿puede manejar a un país?”.
          Frase de la película Invictus.
        </p>
        <p>
          Datos del Instituto Nacional Electoral (INE) del año 2015, consignan
          que, por cada 100 pesos presupuestados por el organismo electoral, 73
          fueron utilizados para preparar la logística del proceso de
          votaciones.
        </p>
        <p>
          ¿La logística debería ser lo más importante en el presupuesto de un
          organismo electoral?
        </p>
        <p>
          Para este 2021 el presupuesto autorizado para el INE fue de poco más
          de 26 mil millones de pesos. Las elecciones en México son de las más
          caras en el mundo, se podría decir que es el precio que tiene la
          confianza de los votantes.
        </p>
        <p>
          La desconfianza es el veneno que más teme cualquier árbitro electoral,
          y no solo la desconfianza de contar bien o mal los votos, ya que hemos
          escalado a un nuevo nivel de desconfianza, lo explico: las actuales
          elecciones pasarán a la historia como las más violentas desde que
          tenemos democracia real (año 2000), a la ciudadanía y todos los
          involucrados en el proceso electoral no solo les preocupa que existan
          condiciones sanitarias para ir a votar -uso de cubrebocas, gel
          antibacterial y sana distancia- también se vive una alerta
          generalizada por la seguridad física de los votantes, pues durante los
          meses de marzo y abril fueron asesinados 28 funcionarios municipales,
          26 aspirantes a elección popular, 17 exfuncionarios, 14 funcionarios
          federales o estatales, 10 activistas, cinco excandidatos, 4
          periodistas, 4 líderes partidistas, 4 militantes de partidos
          políticos, 3 jueces y 3 alcaldes.
        </p>
        <p>
          Otro factor de caos electoral en esta campaña es la disputa mediática
          que hemos presenciado entre el representante del poder ejecutivo y los
          consejeros electorales, así como también los diversos ataques entre
          candidatos, que no son un tema menor, pues han pasado de ser la típica
          guerra sucia electoral, para convertirse en amenazas reales, y quizá
          podrían justificarse diciendo que la guerra sucia es algo inherente al
          proceso, pero en un contexto de violencia, ya vimos que cuesta vidas
          humanas.
        </p>
        <p>
          Ya no podemos permitir que la desconfianza sea algo recurrente en
          nuestros procesos electorales, si queremos una democracia con árbitros
          electorales fuertes, debemos tener procesos electorales fincados en
          las mejores prácticas internacionales, que se encuentran en este caso
          en la Norma ISO 54001. Los organismos electorales no pueden y no deben
          escapar a la tendencia de la modernización y mucho menos a la búsqueda
          de herramientas dirigidas a perfeccionar los servicios que ofrecen.
        </p>
        <p>
          Los Sistemas de Gestión de la Calidad son una herramienta que permite
          a las organizaciones satisfacer las necesidades de sus usuarios con
          altos estándares en la prestación de sus servicios. Para los órganos
          electorales la norma ISO/TS 54001, les permite fortalecer la confianza
          de los ciudadanos en los procesos electorales; en otras palabras;
          contribuye a consolidar una democracia participativa justa.
        </p>
        <p>
          La consolidación de la democracia no solo tiene que ver con garantizar
          que se validen y cuenten los votos, o la asignación equitativa de
          curules a los partidos políticos, tiene que garantizarse todo el
          proceso: registro de asociaciones políticas y candidatos, logística
          electoral, emisión del voto, conteo de votos y declaración de
          resultado, educación electoral y resolución de disputas electorales.
          Esto se logra implementando Sistema de Gestión de la Calidad Electoral
          (SGCE).
        </p>
        <p>
          Implantar un SGCE en el órgano nacional, en los órganos electorales
          estatales, y en los tribunales que califican las elecciones,
          permitiría que antes de cualquier proceso electoral se estandarizaran
          los requerimientos mínimos necesarios para garantizar: una logística
          propia de las circunstancias de desconfianza; asegurar que el
          financiamiento a los actores políticos atienda a criterios reales de
          gastos de campaña; se establezcan sanciones congruentes para casos de
          violencia política en razón de género y en general, transmitir a la
          ciudadanía la confianza de tener un árbitro electoral de calidad.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>