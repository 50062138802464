<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Aspectos metodológicos en el desarrollo de las
            cartas de servicios con base en la Norma UNE 93200:2008
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>Conocer los aspectos metodológicos para el desarrollo de las cartas de servicio para su implantación en una organización administrativa pública, órgano autónomo, y entidades y empresas públicas y privadas.</p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>Al término del curso, los asistentes identificaran los pasos metodológicos que se deben seguir para la implantación de las cartas de servicios en una organización de la administración pública, órganos autónomos, y entidades y empresas públicas y privadas.</p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>La alta dirección, jefes, coordinadores y personal involucrado en la implantación y certificación de las cartas de servicio en las organizaciones de la administración pública, órganos autónomos, entidades y empresas públicas y privadas.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>
                    <b>Estructura y contenido</b>
                  </li>
                  <ul>
                    <li>Información. Debe contener una información mínima relativa a aspectos concretos.</li>
                    <li>Compromisos de calidad e indicadores que permitan medir su cumplimiento.</li>
                    <li>Mecanismos de comunicación externa e interna.</li>
                    <li>Medidas de subsanación o compensación en el caso de incumplimiento de los compromisos.</li>
                    <li>Formas de presentación de sugerencias, quejas y reclamaciones relativas tanto a la prestación de los servicios incluidos en la carta como al incumplimiento de los compromisos asociados a los servicios.</li>
                  </ul>
                  <li>
                    <b>Desarrollo de la carta de servicio</b>
                  </li>
                  <ul>
                    <li><b>Elaboración</b>. Implica:</li>
                    <ul>
                      <li>Decisión de dirección de elaborar la carta de servicios.</li>
                      <li>Constitución del equipo de trabajo.</li>
                      <li>Identificación de las expectativas de los usuarios para cada servicio que recoja la carta.</li>
                      <li>Identificación de Procesos ligados a los servicios de la carta.</li>
                      <li>Comparación del nivel de prestación de los servicios objeto de la carta con las expectativas del usuario.</li>
                      <li>Establecimiento de compromisos de calidad e indicadores para su medición.</li>
                      <li>Redacción de la carta de servicios.</li>
                      <li>Aprobación de la carta de servicios.</li>
                    </ul>
                    <li><b>Seguimiento de los compromisos de las cartas de servicios</b>, por medio de los indicadores, de las quejas y reclamaciones por incumplimiento de los compromisos.</li>
                    <li>
                      <b>Revisión y actualización de las cartas de servicios</b>                      
                    </li>
                  </ul>
                  <li>
                    <b>Comunicación interna y externa </b>
                  </li>
                  <ul>
                    <li>La organización debe planificar las acciones internas y externas del contenido y los resultados de cumplimiento de los compromisos de la Carta de servicios.</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>4 días 12 horas (3 horas por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>21, 22 y 26, 27 de junio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  