<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">Nombre del curso: Confección y gestión de una carta de servicio</div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Conocer y comprender el uso de las Cartas de Servicios, como
                herramienta de compromiso ante los usuarios, en la labor
                constante de mejora continua del trabajo que se desarrolla en su
                beneficio.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                La finalidad del curso residirá en educar a los participantes
                respecto de la relevancia de la implantación de las cartas de
                servicios en organismos públicos, autónomos y entidades públicas
                y privadas mediante una breve introducción de estas como
                instrumentos de gestión, sus definiciones, misión, beneficios y
                compromisos de calidad.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>La alta dirección y las personas responsables
                de la elaboración, gestión y verificación de las cartas de
                servicio en una organización pública, privada, o autónomas.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de
                conocimientos a través de una metodología que permita elegir la
                información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una
                constancia de participación con valor curricular, con la
                insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>
                    <b>Introducción a las cartas de servicios como instrumentos de gestión en las administraciones públicas, privadas y autónomas.</b>
                  </li>
                  <ul>
                    <li>El nuevo contexto en la administración, pública, privada y autónoma.</li>
                    <li>Calidad a la carta de servicios para ser integrada en la gestión del ente público. </li>
                  </ul>
                  <li>
                    <b>¿Qué son las cartas de servicios?</b>
                  </li>
                  <ul>
                    <li>Definición de las cartas de servicios.</li>
                    <li>Misión de las cartas de servicios.</li>
                    <li>Compromisos de calidad entre el organismo público, privado o autónomo y sus usuarios.</li>
                    <li>¿Por qué las cartas de servicios para organismos de la administración pública, organismos autónomos, y entidades y empresas públicas y privadas?</li>
                    <li>¿Que implican las cartas de servicio?</li>
                    <li>Beneficios en la aplicación de las cartas de servicios.</li>
                    <li>Contenido de una carta de servicios.</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>3 días, 6 horas (2 horas por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 7 al 9 de junio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  