<template>
  <div class="container-fluid">
    <h2 class="my-h2">Servicio de implantación</h2>
    <div class="general-text">
      <p>
        Asesoramos a todos los centros de trabajo públicos, privados y sociales establecidos en la República Mexicana, de cualquier tamaño, sector o actividad.
      </p>
      <p>
        Desarrollamos prácticas que se deben cumplir en la norma, tales como:
        <ul>
          <li>Procesos de reclutamiento, selección y capacitación de personal, con igualdad de oportunidades.</li>
          <li>Medición del clima laboral.</li>
          <li>Uso de lenguaje incluyente, no sexista y accesible.</li>
          <li>Salas de lactancia.</li>
          <li>Flexibilización de horarios de trabajo.</li>
          <li>Licencias de paternidad.</li>
          <li>Medidas para apoyar necesidades de cuidado que contemplen la diversidad de familias y hogares.</li>
          <li>Accesibilidad de espacios físicos.</li>
          <li>Mecanismos para prevenir, atender y sancionar la discriminación y la violencia laboral.</li>
        </ul>
      </p>
      <p>
        Hemos diseñado la <b>Guía de Aplicación de la NMX-R-025-SCFI-2015</b> para los centros de trabajo, aportando nuestra experiencia para que, mediante un lenguaje claro y sencillo, se convierta en una herramienta para conocer los cumplimientos del sector, así como los documentos que se deben elaborar y presentar como evidencia durante el desarrollo de una pre auditoría o auditoría para obtener la certificación o renovación del certificado, misma que nuestros consultores emplearán para emitir propuestas específicas e integrales, con el fin de cumplir con los requisitos que solicita la norma, además de:
        <ul>
          <li>Crear una imagen positiva del centro de trabajo y sus productos o servicios ante el mercado o la población.</li>
          <li>Generar mayor compromiso, lealtad e identidad hacia la organización por parte del personal.</li>
          <li>Suscitar un ambiente de trabajo a favor de la diversidad, igualdad e inclusión, lo que atrae y retiene talento.</li>
          <li>Disminuir la incidencia de prácticas discriminatorias y de violencia, además de que facilita su tratamiento y solución.</li>
          <li>Promover el equilibrio entre las responsabilidades familiares, laborales y personales.</li>
        </ul>
      </p>
      <!--download text-->
      <div class="mb-3" style="text-align: center">
        <a class="download-text" href="/pdf/GUIA DE APLICACION 025 2022.pdf" target="_blank">
          Descargue la Guía de Aplicación aquí
          <i class="fa fa-cloud-download download-text"></i>
        </a>
      </div>
      <!--download text end-->
    </div>
  </div>
</template>
        
<script>
export default {
  name: "ServicioDeImplantacionView",
};
</script>
        
<style>
</style>