<template>
  <div class="container-fluid">
    <h1 class="my-h1">Gestión Antisoborno 37001</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Gestión Antisoborno 37001</li>
      </ol>
    </nav>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        La norma 37001 tiene nuevos mecanismos y características únicas para que las organizaciones puedan mitigar y combatir el soborno, Visión y Estrategia Consultoría ha diseñado un curso a medida de las necesidades de toda organización.
      </p>
      <p>
        Un curso que presenta los requisitos y principios más importantes de la Gestión Anti- Soborno, actualizando tus conocimientos con herramientas y mejores prácticas para ayudar a las organizaciones a establecer, implementar, mantener y mejorar un programa o sistema de Gestión Anti Soborno.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Requisitos de la norma ISO 37001 Sistema de
            Gestión Anti-Soborno.
          </li>
          <li>
            Adecuación cómo un eficaz Sistema de Gestión
            Anti-soborno puede adecuarse a su organización e
            integrarse con otros sistemas de gestión
            existentes.
          </li>
          <li>
            Introducción a los sistemas de gestión y el
            enfoque basado en procesos.
          </li>
          <li>
            Marco normativo y metodologías relacionadas con el
            combate al Soborno.
          </li>
          <li>
            Principios fundamentales de un Sistema de Gestión
            Anti-soborno.
          </li>
          <li>
            Planificación y preparación de la auditoría,
            actividades de recolección de información,
            evidencias y verificación de la información.
          </li>
          <li>
            Evaluación de los conocimientos adquiridos del
            curso.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>