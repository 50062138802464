<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Aplicación del protocolo para prevenir, atender y erradicar la
          violencia laboral.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Aplicación del protocolo para prevenir, atender y erradicar la violencia
      laboral.
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/estres.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          <b>
            <em>
              ¿Acaso no haces nada bien?, ¡Qué sea la última vez que...!,
              ¡Tienes suerte de trabajar aquí, en otros lados…!, ¡No es mi
              problema!, ¡Para eso te pago!, ¡El que manda soy yo!, ¡No te pago
              por pensar, te pago por hacer!...
            </em>
          </b>
        </p>
        <p>
          La violencia laboral pasó, de ser ocasional y espontanea, a ser una
          herramienta para controlar y amedrentar al personal sin importar si se
          tiene o no la razón. Para quien la ejerce lo que importa es que se
          note su autoridad, aún a costa de la salud mental y financiera de
          todas y todos quienes forman parte del centro de trabajo, incluyendo a
          los socios de negocio.
        </p>
        <p>
          La reforma a la Ley Federal del Trabajo en el artículo 132, fracción
          XXXI, establece como obligación patronal poner en marcha un protocolo
          para prevenir la discriminación por razones de género y atención de
          casos de violencia, acoso u hostigamiento sexual.
        </p>
        <p>
          El protocolo es un mecanismo efectivo para la adecuada gestión interna
          del problema, ya que pone especial énfasis en la violencia de género
          contra las mujeres, al tiempo que constituye un instrumento para
          fortalecer la igualdad y no discriminación en beneficio del personal.
          En el ámbito laboral, el 39% de las 55.7 millones de personas ocupadas
          en México son mujeres que desarrollan su trabajo en una atmósfera de
          violencia en su contra.
        </p>
        <p>¿Qué es la violencia laboral?</p>
        <p>
          Es una forma de abuso de poder en donde existe un vínculo laboral
          -independientemente de la relación jerárquica- que tiene la finalidad
          de excluir o reprimir al otro por medio de la agresión física,
          violencia sexual o violencia psicológica, que daña la autoestima,
          salud, integridad, libertad y seguridad de la víctima, e impide su
          desarrollo y atenta contra la igualdad. En promedio cada mujer tiene 3
          agresores a lo largo de su vida laboral, entre los que se encuentran:
          Compañero(a) de trabajo, patrón(a) o jefe(a), supervisor(a), capataz,
          coordinador(a), gerente, directivo o ejecutivo, cliente, persona
          desconocida del trabajo o familiar del patrón.
        </p>
        <p>
          Además, la violencia ejercida contra ellas se origina en las
          instalaciones del trabajo, y en menor medida en las inmediaciones. Se
          destaca que 47.9% son agresiones son carácter sexual (ENDIREH, 2016).
          Es claro que las mujeres suelen estar más expuestas al hostigamiento y
          acoso laboral, experimentando procesos de estrés, ansiedad, fobias,
          trastornos del sueño y perdida de la autoestima.
        </p>
        <p>¿Qué hacer para para disminuir la violencia laboral?</p>
        <p>
          Es urgente contar con protocolos para erradicar este tipo de
          violencia, ya que contienen mecanismos, estrategias y acciones
          debidamente sistematizadas por especialista en la materia. Por
          ejemplo, si los centros de trabajo se encuentran implementando la
          Norma NMX 025 en Igualdad Laboral y No Discriminación, inmediatamente
          entran en operación mecanismos y regulaciones para prevenir, atender y
          sancionar las prácticas de discriminación y violencia laboral de
          acuerdo con la naturaleza de la violencia que se ejerce.
        </p>
        <p>
          Un protocolo de esta naturaleza funciona con actores y acciones
          estratégicas dentro de la organización, destacándose un conjunto de
          figuras de autoridad como una persona consejera, un comité de
          atención, seguimiento y canalización, acorde con el tipo de violencia
          que se ejerce; y entre las acciones estratégicas se consideran medidas
          de protección a las víctimas, medidas y sanciones para modificar el
          comportamiento de agresores/agresoras, para dar solución a las
          víctimas y acciones de difusión del mecanismo (protocolo).
        </p>
        <p>
          Contar con el mecanismo salvaguarda la dignidad, integridad y
          bienestar emocional del personal, estableciendo un ambiente laboral
          saludable y armonioso.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>