<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Planes de Igualdad, el camino hacia la inclusión de las mujeres en el
          trabajo
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Planes de Igualdad, el camino hacia la inclusión de las mujeres en el
      trabajo
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/justice.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b
            >“Un centro de trabajo que busca mejorar su productividad, no puede
            prescindir de promover la igualdad”</b
          >
        </p>
        <br />
        <p>
          En el año 2017 la Organización para la Cooperación y el Desarrollo
          Económico (OCDE), de la cual México forma parte, publicó el documento
          “Construir un México inclusivo: políticas y buena gobernanza para la
          igualdad de género” en el que se destaca que las naciones
          pertenecientes a la OCDE promedian un 67% de fuerza de trabajo
          femenino; por su parte, México se encuentra por debajo con un 47%, lo
          cual da cuenta del enorme reto que tenemos a cuestas.
        </p>
        <p>
          Ahí se menciona que dos terceras partes de las mujeres que laboran lo
          hacen bajo un esquema informal, es decir, que las condiciones de sus
          empleos no generan derechos laborales básicos como estándares
          salariales mínimos, acceso a seguridad social, acceso a equipo de
          trabajo adecuado o inclusive atención a mujeres en caso de violencia.
        </p>
        <p>
          En los centros de trabajo es donde se tiene que eliminar cualquier
          tipo de desigualdad, discriminación y violencia. Precisamente es esta
          premisa sobre la que se estructura un Plan de Igualdad Laboral; un
          camino diseñado para organizaciones públicas y privadas que buscan
          integrar una igualdad de trato en sus procedimientos de gestión.
        </p>
        <p>
          Es preciso señalar que un Plan de Igualdad Laboral es un proceso de
          conciliación que demanda la participación activa de todas y todos los
          que colaboran en la organización, así como del compromiso de la alta
          dirección por adoptarlo. La implementación de este plan conlleva
          establecer reglas claras en torno a las oportunidades de acenso,
          capacitación y reclutamiento igualitarias.
        </p>
        <p>
          Las organizaciones que apuestan por este tipo de prácticas se ven
          beneficiadas con: 1) retención del talento; en un contexto en el que
          la superación personal se ve reflejada en la búsqueda constante por
          mejores condiciones laborales, que resulta necesario garantizarlas; 2)
          clima laboral, un ambiente de trabajo que garantiza la sana
          convivencia, mejora el desempeño individual y por ende el del centro
          de trabajo; 3) preparación para el futuro, los seres humanos siempre
          queremos estar mejor preparados y una empresa que ofrece capacitación,
          se vuelve atractiva para colaborar; y 4) reputación, pues ante la
          opinión de los interesados mejora la imagen del centro de trabajo.
        </p>
        <p>
          La igual laboral no se logra de la noche a la mañana; en el caso del
          Plan de Igualdad Laboral se requiere seguir un proceso de 5 etapas: 1.
          Compromiso de la alta dirección y formación de un comité de igualdad
          laboral y no discriminación; 2. Diagnóstico de la situación del centro
          de trabajo en el tema de igualdad laboral; 3. Programación y
          definición de acciones; 4. Implantación del Plan de Igualdad Laboral;
          y 5. Evaluación y mejora continua.
        </p>
        <p>
          Para dimensionar un poco el caso mexicano, debemos decir vamos tarde,
          muestra de ello es que el pasado 13 de octubre de 2020, España emitió
          un Decreto Real que regula los Planes de Igualdad, al mismo tiempo que
          <b
            >los vuelve obligatorios para todas aquellas empresas con 50 o más
            personas trabajadoras.
          </b>
        </p>
        <p>
          Sabemos que en México existen herramientas como la Norma Mexicana NMX
          R 025 SCFI 2015 en Igualdad Laboral y No Discriminación, cuya adecuada
          implementación genera resultados equivalentes a los alcanzados por las
          empresas españolas. Entre más mecanismos de igualdad laboral sean
          promovidos, será más fácil alcanzar la visión de la Organización de
          las Naciones Únicas (ONU), que en 2017 aseguraba que sumar la
          participación de las mujeres al ámbito laboral podría generar un
          aumento del 26% en el PIB mundial para el año 2025
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>