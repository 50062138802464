<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Formación de equipo auditor en la Norma ISO 31000:2018, Sistema de Gestión de Riesgos 
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>
                    Conocer y adquirir capacidades para efectuar auditorías internas de los sistemas de gestión conforme a la norma ISO 31000:2018.
                  </li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso permitirá a los participantes comprenderán el concepto del sistema de gestión basado en la Norma ISO 31000:2018; la función de una auditoría interna para mantener y mejorar los sistemas de gestión de riesgos.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>La alta dirección y funcionarios/as designados/as para ser auditor/a interno/a en la aplicación del Sistema de Gestión en la Norma ISO 31000:2018.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ul class="ul-clean">
                  <li>
                    <b>Tema 1. </b>Generalidades de las auditorías a los sistemas de gestión de riesgos.                               
                  </li>
                  <li>
                    <b>Tema 2. </b>Requisitos de auditorias a los sistemas de gestión de riesgos.
                  </li>
                  <li>
                    <b>Tema 3. </b>Requisitos de auditorías internas en ISO 31000:2018.
                  </li>
                  <li>
                    <b>Tema 4. </b>Habilidades y características esperadas de un auditor.
                  </li>
                  <li>
                    <b>Tema 5. </b>Planeación de la auditoría.
                  </li>
                  <li>
                    <b>Tema 6. </b>Realización de las auditorías.
                  </li>
                  <li>
                    <b>Tema 7. </b>Redacción adecuada de no conformidades.
                  </li>
                  <li>
                    <b>Tema 8. </b>El cierre de acciones correctivas.
                  </li>
                  <li>
                    <b>Tema 9. </b>Evaluación de los candidatos a auditor.
                  </li>
                </ul>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>16 horas. 4 días (4 horas por día) 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 3 al 6 de julio de 2023
            </div>

  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  