<template>
  <div class="container-fluid">
    <h1 class="my-h1">Desarrollo de Habilidades Directivas</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Desarrollo de Habilidades Directivas</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        El gerente o director de una organización debe estar preparado para manejar en forma eficaz la comunicación con su equipo de trabajo, las habilidades directivas permiten solucionar el conflicto entre compañeros, saber motivar y escuchar a los que tenemos alrededor.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        El no conocer las habilidades y competencias necesarias gerenciales, se convierte en un grave problema de gestión y acción, es indispensable que los mandos gerenciales sepan mejorar el desempeño de su sector, área u organización, para responder a los cambios.
      </p>
      <p>
        Un gerente o director en su organización necesita desarrollar capacidad de gestión de manera continua a fin de mejorar sus habilidades para tomar mejores decisiones, crear y liderar equipos de trabajo con orientación a resultados deseados.
      </p>
      <p>
        Desarrolla tus habilidades analíticas y creativas para la solucionar problemas, establece la relación existente entre la estructura organizacional y la consecución de resultados positivos.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Desarrollo Personal.
          </li>
          <li>
            Desarrollo del líder interno.
          </li>
          <li>
            El Líder y la Estrategia.
          </li>
          <li>
            Planeación estratégica.
          </li>
          <li>
            Diferenciar lo urgente y lo importante.
          </li>
          <li>
            Comunicación Efectiva.
          </li>
          <li>
            La delegación de funciones.
          </li>
          <li>
            Desarrollo de equipos de trabajo.
          </li>
          <li>
            Equipo Vs Grupo.
          </li>
          <li>
            Los colaboradores del líder.
          </li>
          <li>
            Sinergia
          </li>
          <li>
            Planes de mejora y revisión organizacional.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>