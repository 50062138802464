<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          10 puntos críticos de un Sistema de Gestión Antisoborno (SGAS).
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      10 puntos críticos de un Sistema de Gestión Antisoborno (SGAS).
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/checklist.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          Todos los sistemas de gestión tienen requisitos que identifican
          particularmente la finalidad que persiguen, por ejemplo, un sistema de
          igualdad laboral pone especial interés en el proceso de reclutamiento
          y ascenso; el sistema de gestión de la calidad en el mapeo exacto de
          procesos; y el sistema antisoborno, pone especial énfasis en 10 puntos
          críticos que posibilitan una implantación exitosa:
        </p>
        <ol>
          <li>
            El más importante: La relación directa de la empresa con el
            gobierno, dado que considera cualquier contacto con un servidor
            público como riesgoso para la organización, obligando a tener una
            atención clara del desenvolvimiento de dicha relación.
          </li>
          <li>
            Un procedimiento de Debida Diligencia (due diligence) que genere un
            mapa de riegos para investigar a los socios de negocios, verificando
            la conducta mostrada a través del tiempo en el ramo comercial donde
            se desempeñan. También se investiga la reputación de todas las
            personas que tienen una relación comercial con la empresa.
          </li>
          <li>
            Los controles financieros que debe tener la empresa para evitar
            problemas de fraude de cuello blanco y lavado de dinero.
          </li>
          <li>
            Control estricto de las áreas de compras, obligando a que los
            responsables directos de efectuarlas acepten contratos de trabajo
            renovables por periodos cortos de tiempo (se recomienda
            trimestralmente), con la finalidad de que sí se detectara algún
            soborno, se pueda despedir a los involucrados sin mayor
            contratiempo.
          </li>
          <li>
            Revisión y firma periódica de ordenamientos jurídico administrativos
            que penan el soborno por parte de los empleados y directivos. La
            norma lo dispone pues considera que las personas aceptan el soborno
            porque no tienen claras en muchas ocasiones las sanciones legales
            que ello acarrea.
          </li>
          <li>
            Dependencia directa del órgano de gobierno. Un sistema de gestión de
            esta naturaleza no debe depender de la Dirección General. Se
            consigna claramente que la información debe fluir al máximo órgano
            decisorio, de tal forma que no se contamine ninguna investigación.
            Si se decidiera establecer un SGAS en un ayuntamiento, el Cabildo,
            como máximo órgano de autoridad, debe hacer las veces de consejo
            directivo y recibir los reportes de auditoría interna del sistema,
            por dar un ejemplo preciso.
          </li>
          <li>
            Identificación de partes interesadas. Se refiere a la necesidad de
            identificar a todos los actores internos y externos que requieren
            desterrar los sobornos de su trabajo diario, por ello, el mapa de
            riesgos deberá identificar las áreas que pueden tener este problema
            y realizar un conjunto de acciones preventivas documentadas que lo
            limiten.
          </li>
          <li>
            La norma establece que se deben poner montos específicos para
            acciones de hospitalidad, entendida como comidas, viajes,
            transportación, y hospedajes, entre otros, a pagar por parte de la
            organización a las personas con quienes se realizan negocios, de tal
            manera que haya evidencia que cualquier pago desproporcionado.
          </li>
          <li>
            Se debe establecer un procedimiento claro para que el empleado sepa
            que hacer exactamente en el momento que reciba una proposición de
            soborno, para que quede a salvo su empleo.
          </li>
          <li>
            Todas las personas de la organización deben saber que el SGAS está
            funcionando en la empresa, para que se envíe el mensaje claro de que
            la lucha contra el soborno es una política institucional.
          </li>
        </ol>
        <p>
          Como podrán observar, es difícil articular algunos de estos requisitos
          al sector público, pues se trata de una norma cuya finalidad es
          proteger a la empresa en la relación que tiene con el gobierno, al
          punto de llegar a plantear que, si algún funcionario público solicita
          un soborno por algún trámite, y si no lograr ese trámite puede llevar
          a la quiebra a la empresa, es necesario que se concrete el soborno.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>