<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Discriminación laboral
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Discriminación laboral</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/discriminate.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <div class="d-flex justify-content-center">
                <b>Solo 3 de cada 10 empresas tienen una cultura de No Discriminación</b>
              </div>
              <div class="d-flex justify-content-end">
                <b>Ramón Cuevas Martínez</b>
              </div>
              <br/>
              <p style="text-align:center;">
                <b>
                  Frase común para ofertar un empleo:
                  <br/>
                  “Buscamos un trabajador joven, enérgico y ágil para unirse a nuestro equipo”
                </b>
              </p>
              <br/>
              <p>
                Vivimos una discriminación laboral estructural, es común despedir del trabajo a las mujeres por resultar embarazadas; es más común aún solicitar certificado médico de ingravidez como requisito para contratar laboralmente a la mujer; también es común negar el acceso a personas con discapacidad (ciegas, en sillas de ruedas) o por apariencia física, a establecimientos comerciales que prestan u ofrecen servicios al público; se discrimina la homofobia, la lesbofobia, y también se despide a personas adultas mayores, o por tener alguna discapacidad (deficiencia física, intelectual, mental o sensorial).
              </p>
              <p>
                Se discrimina a grupos de personas por características físicas o por su forma de vida, el origen étnico o nacional, el sexo, la edad, la discapacidad, la condición social o económica, la condición de salud, el embarazo, la lengua, la religión, las opiniones, las preferencias sexuales, el estado civil y otras diferencias pueden ser motivo de distinción, exclusión o restricción de sus derechos.
              </p>
              <p>
                Según CONAPRED la discriminación es una práctica lamentablemente cotidiana que consiste en dar un trato desfavorable o de desprecio inmerecido a determinada persona o grupo, que a veces no percibimos, pero que en algún momento la hemos causado o recibido.
              </p>
              <p>
                De acuerdo con una encuesta realizada por OCCMundial en 2017, <b>64% de los profesionistas aseguran que en la empresa en la que trabajan existe discriminación, 65% de los profesionistas declaró que han sufrido algún tipo de discriminación en el trabajo.</b>
              </p>
              <p>
                Analizando las respuestas de más de la mitad de los encuestados, las situaciones que generan mayor discriminación laboral en México son:
                <ul>
                  <li>
                    <b>
                      La edad (52%)
                    </b>
                  </li>
                  <li>
                    La apariencia física (52%)
                  </li>
                  <li>
                    La imagen personal (forma de vestir, de arreglarse, el uso de tatuajes o piercings (53%).
                  </li>
                </ul>
              </p>
              <p>
                También se discrimina por la <b>orientación sexual (32%)</b>, por la <b>condición socioeconómica (31%)</b>, por tener una <b>discapacidad (27%)</b>, por <b>género (25%)</b>, y en menor medida, por <b>religión (13%)</b> y <b>cultura (12%)</b>.
              </p>
              <p>
                La principal consecuencia que han sufrido los trabajadores es la <b>falta de contratación (60%)</b>; otros no han sido tomados en cuenta para una <b>promoción (21%)</b>, a <b>algunos no los consideran parte del equipo de trabajo (14%)</b> y otros han sufrido bullying laboral, también llamado <b>mobbing (6%)</b>.
              </p>
              <p>
                <b>Sólo 3 de cada 10</b> de los participantes en el sondeo <b>dijo que en su empresa hay una cultura de No Discriminación</b>, es decir, hay oportunidades sin importar la apariencia física, género, orientación sexual, edad, discapacidad, religión o condición socioeconómica.
              </p>
              <p>
                Los efectos de la discriminación en la vida de las personas son negativos y tienen que ver con la pérdida de derechos y la desigualdad para acceder a ellos; lo cual puede orillar al aislamiento, a vivir violencia e incluso, en casos extremos, a perder la vida.
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>