<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          WhatsApp, principal medio digital para ejercer violencia laboral
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">WhatsApp, principal medio digital para ejercer violencia laboral</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/whatsapp.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
                <b>
                  <em>
                    Aceptar nuestra vulnerabilidad en lugar de tratar de ocultarla es la mejor manera de adaptarse a la realidad.
                  </em>
                </b>
              </p>
              <br/>
              <p>
                Vivimos tiempos de rinocerontes grises donde es costumbre para algunos ejecutivos voltear a otro lado cuando se identifica una vulnerabilidad, entre todas las existentes, la violencia laboral una de las más peligrosas, por lo que su tratamiento y posibles consecuencias deben ser parte de la agenda de trabajo de empresas e instituciones. 
              </p>
              <p>
                Datos de la Revista Forbes concluyen que nueve de cada 10 mexicanos consideraron que el acoso laboral es un problema grave y debería existir una legislación que castigue cualquier forma de acoso laboral.
              </p>
              <p>
                En cuanto a las formas más comunes del acoso laboral están los medios cibernéticos y es el WhatsApp o los mensajes de texto que ocupan 47%, otro 38% por comentarios negativos en foros de discusión, 36% en rumores en redes sociales, 25% por información humillante en sitios web y 18% a través de envío de fotos inapropiadas.
              </p>
              <p>
                <b>La violencia laboral está presente en todos lados</b>, se ejerce por las personas que tienen un vínculo laboral, docente o análogo con la víctima, independientemente de la relación jerárquica, consistente en un acto o una omisión en abuso del poder que daña la autoestima, salud, integridad, libertad y seguridad del empleado, impide su desarrollo y atenta contra la igualdad.
              </p>
              <p>
                En un sentido más amplio y real la violencia laboral también <b>incluye violencia de género, así como acoso sexual, psicológico o moral</b>. Las víctimas sufren ataques directos e indirectos de diversos tipos como psicológicos, morales, e incluso físicos que repercuten en el bienestar mental y físico del trabajador, y además generan su denigración como empleado.
              </p>
              <p>
                En muchas instituciones y empresas está normalizada la violencia laboral, y solo cuando tomamos conciencia de su impacto negativo en el ambiente de trabajo podemos notar que:
                <ul>
                  <li>
                    Se trata de un tipo de violencia que busca excluir y perjudicar a un compañero de trabajo, bien sea del mismo rango o inferior.
                  </li>
                  <li>
                    Puede ser ejercida por los mismos compañeros de trabajo, con quienes se comparte el mismo rango de labores y responsabilidades, pero que propician este tipo de actos por diversos motivos.
                  </li>
                  <li>
                    Puede manifestarse a través del acoso laboral, agresiones físicas o maltratos psicológicos como burlas y sarcasmos.
                  </li>
                  <li>
                    La víctima puede ser objeto de discriminación debido a su origen, cultura, religión o color de piel.
                  </li>
                </ul>
              </p>
              <p>
                La manera más efectiva de atender la violencia laboral es midiendo sistemáticamente el ambiente de trabajo, para identificar las prácticas recurrentes, y por supuesto, establecer un sistema de gestión basado en la NMX 025, que posibilita la existencia de un protocolo para desterrar estas conductas nocivas. Debemos comprender que se trata de un problema que, al menos en la última década, no se ha logrado resolver ya que mientras que en 2010 la tasa de abandono laboral por acoso ascendía a 43.9 personas por cada 100,000 ocupadas, en el 2019 fue de 43.5. De hecho, en 2019 hubo un aumento de esta tasa de más del 60% con respecto al año anterior.
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>