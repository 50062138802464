<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Análisis ejecutivo e impacto de la
            NMX-CC-54001-IMNC-2020 en los organismos electorales
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Conocer y comprender los temas siguientes para el desarrollo de un análisis de impacto en la organización conforme a la NMX-CC-54001-IMNC-2020:
                <ul>
                  <li>Utilidad del análisis de impacto dentro de la organización y la relación del estudio de impacto y la evaluación de riesgos del órgano electoral.</li>
                  <li>Entendimiento de la organización, sus procesos clave y los recursos de un sistema de tecnologías de información que lo soportan.</li>
                  <li>Metodología y técnicas para diseño de un análisis de impacto de la organización electoral.</li>
                  <li>Evaluación de impacto referente al organismo electoral si las actividades y funciones fueran interrumpidas.</li>
                  <li>Identificación del nivel mínimo de desempeño en que una actividad puede realizarse en respuesta a una interrupción.</li>
                  <li>Establecimiento del tiempo máximo tolerable de interrupción dentro del cual las actividades deben ser restauradas a su nivel normal.</li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El propósito del curso consistirá en enseñar a los participantes sobre los conceptos y técnicas para el desarrollo de un análisis de impacto en las organizaciones electorales que les permita identificar las actividades críticas de estas y su huella en caso de falla. 
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Las personas que requieren comprender mejor las actividades críticas de la organización y el impacto adverso en caso de una interrupción, las personas que se encuentran en proceso de desarrollo de un Plan de la Organización o un Plan de Recuperación de Desastres, las personas que requieren entender y adquirir nuevos conocimientos en el desarrollo de un análisis de impacto de la organización, y los profesionales de los sistemas de tecnologías de información que apliquen o vayan a aplicar el ISO 54001
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Introducción al análisis de impacto a la organización.</li>
                  <ul>
                    <li>¿En qué consiste el análisis de impacto en la organización?</li>
                    <li>Propósitos y Necesidad de un análisis de impacto en la organización.</li>
                    <li>Definiciones Importantes.</li>
                    <li>Entradas y Salidas.</li>
                    <li>Requerimientos de Desarrollo.</li>
                    <li>Métodos y Técnicas.</li>
                    <li>Análisis de la Cadena de Valor.</li>
                    <li>Proceso de Desarrollo del análisis de impacto en la organización.</li>
                    <li> Relación Gestión de Riesgos y el análisis de impacto en la organización.</li>
                    <li>Frecuencia y Disparadores.</li>
                  </ul>
                  <li>Entendimiento de la organización </li>
                  <ul>
                    <li>Conociendo la organización.</li>
                    <li>Elementos.</li>
                    <li>Funciones de negocio.</li>
                  </ul>
                  <li>Funciones vitales de la organización</li>
                  <ul>
                    <li>Reacción humana.</li>
                    <li>Niveles de criticidad.</li>
                    <li>Niveles de impacto.</li>
                    <li>Evaluación de impacto.</li>
                    <li>Puntos de análisis de impacto.</li>
                    <li>El impacto de los sistemas de tecnologías de información.</li>
                  </ul>
                  <li>Prerrequisitos </li>
                  <ul>
                    <li>General.</li>
                    <li>Contexto y alcance.</li>
                    <li>Roles.</li>
                    <li>Compromiso.</li>
                    <li>Recursos.</li>
                  </ul>
                  <li>Realizar el análisis de impacto a la organización</li>
                  <ul>
                    <li>General.</li>
                    <li>Gestión y planeación de proyectos.</li>
                    <li>Priorización de servicios y productos.</li>
                    <li>Priorización de procesos.</li>
                    <li>Priorización de actividades.</li>
                    <li>Análisis y consolidación.</li>
                    <li>Obtener el respaldo de la alta dirección.</li>
                    <li>Selección de la estrategia de continuidad de la organización.</li>
                  </ul>
                  <li>Revisión y monitoreo del proceso el análisis de impacto de la organización</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>4 días 16 horas (4 horas por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 17 al 20 de julio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  