<template>
  <div class="container-fluid">
    <h1 class="my-h1">Gestión Estratégica</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Gestión Estratégica</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Desarrollar habilidades básicas para diseñar y facilitar un proceso de formulación e implantación estratégica, comprende los fundamentos del análisis del entorno de la organización como elemento para la formulación de la estrategia, aplicar herramientas modernas y con alto impacto en la efectividad del proceso de formulación e implantación de la estrategia.
      </p>
      <p>
        Describe exactamente la situación de la empresa y formula estrategias que logren a la organización posicionar mejor frente a la competencia.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Permite entender los conceptos clave de la gestión estratégica, adquiere habilidades para el diseño y facilitación de un proceso de planeación, evalúa las estrategias derivadas del proceso de planeación.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            El proceso de gestión estratégica.
          </li>
          <li>
            Análisis interno y externo de la organización.
          </li>
          <li>
            Selección e implementación de estrategias.
          </li>
          <li>
            Estrategias y ventajas competitivas.
          </li>
          <li>
            Administración de la organización interna para promover una mejor ejecución de la estrategia.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>