<template>
  <div class="container-fluid">
    <h1 class="my-h1">Indicadores para el Sistema de Gestión</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Indicadores para el Sistema de Gestión</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Para que tu organización tenga un sistema de gestión efectivo y práctico que mejore el desempeño y competitividad, es necesario aprehender a diseñar, implementar y generar indicadores clave de desempeño.
      </p>
      <p>
        Actualmente el desarrollo de las organizaciones demanda contar con mediciones que permitan tomar decisiones frente a situaciones complejas y exigencias de la economía actual. Las organizaciones necesitan conocer en profundidad qué deben medir, donde es conveniente hacerlo, en qué momento, con qué frecuencia y quién debe medir y finalmente cómo difundir los resultados.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Medir y analizar es fundamental para saber el estado real de tu organización, para poder realizar correcciones, monitoreo y seguimiento, una de las formas idóneas es elaborar y establecer indicadores.
      </p>
      <p>
        Da a tu organización un diseño, desarrollo e implanta un sistema de gestión con indicadores; revisa con periodicidad la ejecución de estrategias, haz ajustes necesarios al detectar ineficiencia y así asegurar el logro de resultados planeados, con el sistema de gestión logras hacer más competente a tu organización.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Conceptos y definiciones clave de un sistema de
            gestión.
          </li>
          <li>
            Como medir la eficiencia y eficacia en alineación
            de causa y efecto.
          </li>
          <li>
            Estructura de un sistema de gestión.
          </li>
          <li>
            Ficha técnica de indicadores a ser medidos.
          </li>
          <li>
            Etapas y técnicas de medición de un indicador.
          </li>
          <li>
            Tablero de control.
          </li>
          <li>
            Prácticas con indicadores.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
            
<script>
export default {
  name: "CursosView",
};
</script>
            
<style>
</style>