<template>
  <div class="container-fluid">
    <h2 class="my-h2">Transparencia</h2>
    <div class="row justify-content-center">
      <div class="col-12 col-md-11 col-xl-5 py-2">
        <!--card transparencia-->
        <div class="card">
          <img src="@/assets/img/transparencia/transparencia1.jpg" class="w-100" alt="..." />
          <div class="card-body ext-box" style="min-height: 320px;">
            <div class="int-box">
              <h3 class="my-h3">APOYO FINANCIERO</h3>
              <div class="general-text">
                <p>
                  El apoyo financiero de Visión y Estrategia Consultoría es solventado por sus socios y han decidido reservar un porcentaje para conservar una estabilidad financiera, adicionalmente se cuenta con una fianza contratada para asegurar el cumplimiento de cada servicio, derechos y deberes de solicitantes y clientes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--card transparencia end-->
      </div>
      <div class="col-12 col-md-11 col-xl-5 py-2">
        <!--card transparencia-->
        <div class="card">
          <img src="@/assets/img/transparencia/transparencia2.jpg" class="w-100" alt="..." />
          <div class="card-body ext-box" style="min-height: 320px;">
            <div class="int-box">
              <h3 class="my-h3">DERECHOS DE LOS SOLICITANTES</h3>
              <div class="general-text">
                <p>
                  <ul>
                    <li>Recibir un servicio de calidad y calidez.</li>
                    <li>No ser discriminado.</li>
                    <li>Ser tratado con igualdad.</li>
                    <li>Solución a dudas.</li>
                    <li>Atención personalizada.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--card transparencia end-->
      </div>
      <div class="col-12 col-md-11 col-xl-5 py-2">
        <!--card transparencia-->
        <div class="card">
          <img src="@/assets/img/transparencia/transparencia3.jpg" class="w-100" alt="..." />
          <div class="card-body ext-box" style="min-height: 200px;">
            <div class="int-box">
              <h3 class="my-h3">DERECHOS Y DEBERES DE LOS USUARIOS</h3>
              <div class="general-text">
                <p>
                  Puedes descargar el contrato de prestación de servicios
                  <a
                      class="download-here"
                      href="/docx/Contrato de prestación de servicios NOM 035 2018.docx"
                      target="_blank"
                    >
                      aquí </a
                    >.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--card transparencia end-->
      </div>
      <div class="col-12 col-md-11 col-xl-5 py-2">
        <!--card transparencia-->
        <div class="card">
          <img src="@/assets/img/transparencia/transparencia4.jpg" class="w-100" alt="..." />
          <div class="card-body ext-box" style="min-height: 200px;">
            <div class="int-box">
              <h3 class="my-h3">FORMATO DE QUEJAS</h3>
              <div class="general-text">
                <p>
                  Puedes descargar el formato de quejas
                  <a
                      class="download-here"
                      href="/pdf/formato de quejas.pdf"
                      target="_blank"
                    >
                      aquí </a
                    >.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--card transparencia end-->
      </div>
      <div class="col-12 col-md-11 col-xl-5 py-2">
        <!--card transparencia-->
        <div class="card">
          <img src="@/assets/img/transparencia/transparencia5.jpg" class="w-100" alt="..." />
          <div class="card-body ext-box" style="min-height: 300px;">
            <div class="int-box">            
              <h3 class="my-h3">PROCEDIMIENTO DE QUEJAS</h3>
              <div class="general-text">
                <p>
                  Puedes descargar el procedimiento de quejas
                  <a
                      class="download-here"
                      href="/docx/procedimiento de quejas NOM 035 2018.docx"
                      target="_blank"
                    >
                      aquí </a
                    >.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--card transparencia end-->
      </div>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "TransparenciaView",
};
</script>
        
<style>
</style>