<template>
    <div class="container-fluid">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link class="" to="/">Inicio</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link class="" to="/boletines">Boletines</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Que es la NOM-037-STPS-2016 Teletrabajo-Condiciones de Seguridad y Salud en el Trabajo
          </li>
        </ol>
      </nav>
      <h1 class="my-h1">
        Que es la NOM-037-STPS-2016 Teletrabajo-Condiciones de Seguridad y Salud en el Trabajo
      </h1>
      <div class="row">
        <div class="col-sm-4 text-center">
          <img
            src="@/assets/img/boletines/nom037.jpg"
            class="w-100  my-3"
            alt="..."
          />
        </div>
        <div class="col-sm-8 general-text">
          <p>
            Tras la llegada de la pandemia de Covid-19 y las medidas de confinamiento impuestas por los distintos gobiernos, distintos empleos tuvieron que mudar sus operaciones de un espacio laboral común a modalidades remotas de conexión e interacción apoyados por distintas aplicaciones cibernéticas. Esto, como cualquier imprevisto que pueda ocurrir trajo consigo un proceso de adaptación y regulación por parte de los distintos espacios de trabajo. 
          </p>
          <p>
            Sin embargo, durante el año 2021 Banxico realizo un ejercicio comparativo de las capacidades de mudar el empleo a una modalidad teledirigida, donde se arrojaba que, si bien en estados unidos poco mas del 40% de los empleos tenían la capacidad de hacerse desde casa, esta institución tan solo reconoció con la capacidad de realizar sus labores remotos al 10.6% de los empleos, sin mencionar la brecha de genero que se presentó durante el año 2020, donde el 15.3% de los empleos de las mujeres podrían realizar sus funciones bajo esta modalidad, a diferencia del 7.7% de los empleos de los hombres. 
          </p>
          <p>
            Ante este contexto, el gobierno mexicano, de la mano de múltiples secretarias, universidades, empresas de asesoría, colegios y asociaciones, formo la <b>NOM-037-STPS-2016 Teletrabajo-Condiciones de seguridad y salud en el trabajo</b>, entrando en vigor el 2 de diciembre de 2022 y tomando como referencia la NOM-019-STPS-2011, Constitución, integración, organización y funcionamiento de las comisiones de seguridad e higiene, NOM-030-STPS-2009, Servicios preventivos de seguridad y salud en el trabajo-Funciones y actividades y NOM-035-STPS-2018, factores de riesgo psicosocial en el trabajo - Identificación, análisis y prevención, con el objetivo de “Establecer las condiciones de seguridad y salud en el trabajo en los lugares en donde las personas teletrabajadoras realicen sus actividades, a fin de prevenir accidentes y enfermedades, así como promover un medioambiente seguro y saludable en el entorno en el que prestan sus servicios.”(DOF, 2022).
          </p>
          <p>
            El cual obligaba a los patrones a: 
            <ul>
                <li>
                    manejar una lista de los trabajadores que harían teletrabajo con sus datos de identificación.
                </li>
                <li>
                    Acordar los estándares mínimos las zonas de trabajo de los empleados.
                </li>
                <li>
                    Difundir una política de trabajo aun cuando este se realice de forma remota
                </li>
                <li>
                    Informar los riesgos relacionados con la actividad que desarrollen, y de la posible exposición a los agentes y a los factores de riesgo, tanto ergonómico como psicosocial, así como de las posibles consecuencias para la salud asociados a estas exposiciones.
                </li>
                <li>
                    Contar con una lista de verificación validada de las condiciones de seguridad y salud.
                </li>
                <li>
                    Establecer y documentar, en su caso, el proceso de implementación del Teletrabajo para el centro de trabajo.
                </li>
                <li>
                    Proporcionar capacitación al menos una vez al año a los trabajadores en la modalidad de teletrabajo.
                </li>
                <li>
                    Modificar la modalidad de Teletrabajo a trabajo presencial, en su caso, cuando la persona teletrabajadora le informe de alguna condición o alteración de las condiciones de seguridad y salud en el trabajo que justifique el regreso al trabajo presencial.
                </li>
                <li>
                    Dar seguimiento a los avisos de accidente de trabajo que le reporten las personas teletrabajadoras o sus familiares con motivo o en ejercicio de sus actividades de Teletrabajo.
                </li>
                <li>
                    Contar con mecanismos de atención para casos de violencia familiar que consideren, entre otras cosas, el retorno a la modalidad presencial de manera temporal o permanente.
                </li>
            </ul>
          </p>
          <p>A su ves que obliga a los empleados a:
            <ul>
                <li>Brindar al Patrón las facilidades por escrito para una comprobación física de las condiciones de seguridad y salud en el trabajo</li>
                <li>Observar la Política de Teletrabajo que establezca el patrón.</li>
                <li>Informar al patrón cualquier alteración de las condiciones de seguridad y salud en el trabajo</li>
                <li>Resguardar y conservar en buen estado los equipos, materiales, útiles y mobiliario que reciban del patrón para realizar el Teletrabajo.</li>
                <li>Obedecer y conducirse con apego a las disposiciones en materia de seguridad y salud en el trabajo.</li>
                <li>Atender las políticas y mecanismos de protección de datos e información establecidos por el patrón en el desempeño de sus actividades</li>
                <li>Informar por escrito y con antelación al patrón de cualquier cambio de domicilio definitivo o temporal.</li>
                <li>Participar en los procesos de información de riesgos relacionados con sus actividades de teletrabajo.</li>
                <li>Avisar al patrón de los riesgos de trabajo que sufra en el lugar de trabajo</li>
            </ul>
          </p>
          <p>Además de disponer características mínimas del trabajo y de los espacios a utilizar con la intención de prevenir los factores de riesgo provocados por agentes físicos, riesgos ergonómicos y factores de riesgo psicosocial.</p>
          <p>
            Referencias.
            <ul>
                <li>
                    <i>DOF (2022). PROYECTO de Norma Oficial Mexicana PROY-NOM-037-STPS-2022, Teletrabajo-Condiciones de seguridad y salud en el trabajo. Recuperado el 25/05/2023. Secretaria de Gobernación. https://www.dof.gob.mx/nota_detalle.php?codigo=5658278&fecha=15/07/2022#gsc.tab=0</i>
                </li>
                <li>
                    <i>Leyva Gustavo y Mora Israel (2021) ¿Qué tan altas (bajas) son las posibilidades de hacer teletrabajo en México?. Recuperado el 25/05/2023. Banco de México. https://www.banxico.org.mx/publicaciones-y-prensa/documentos-de-investigacion-del-banco-de-mexico/resumenes-ejecutivos/%7B9DF61418-28EE-5F33-A83B-876B29399A06%7D.pdf</i>
                </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "MyName",
  };
  </script>    
  <style>
  </style>