<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del Curso: Obligaciones del patrón conforme a la Norma
            Oficial Mexicana NOM-035-STPS-2018 factores de riesgos Psicosociales
            en el trabajo-identificación, análisis y prevención
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Informar de acuerdo con el tamaño del centro de trabajo (cantidad de trabajadores) los conceptos, lineamientos y procesos a seguir por parte de la NOM 035 STPS 2018 para así proporcionar las herramientas y el desarrollo de evaluaciones de la misma organización.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso facilitará a los asistentes conocimientos respecto de cuáles son las obligaciones que adquiere los patrones de un centro de trabajo al implementar la Norma Oficial Mexicana NOM-035-STPS-2018 factores de riesgos Psicosociales en el trabajo-identificación, análisis y prevención.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Profesionistas, independientes, consultores, académicos, estudiantes, la alta dirección e integrantes de comités de los centros de trabajo públicos, privados y sociales que estén interesados en implantar la Norma Oficial Mexicana NOM-035-STPS-2018 factores de riesgos Psicosociales en el trabajo-identificación, análisis y prevención.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Objetivo y campo de aplicación de la Norma Oficial Mexicana NOM-035-STPS-2018.</li>
                  <li>Definición de la Norma Oficial Mexicana NOM-035-STPS-2018.</li>
                  <li>¿Por qué se hizo la NOM 035?</li>
                  <li>Factores de riesgos psicosociales.</li>
                  <li>Obligaciones del patrón.</li>
                  <li>Identificación y análisis de los factores de riesgo psicosocial y evaluación del entorno organizacional favorable.</li>
                  <li>Medidas de prevención y acciones de control.</li>
                  <li>Implementación de la NOM 035: Manual, Programa de difusión, análisis, plan de acción.</li>
                  <li>Mitos y realidades de la NOM 035.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>3 días 6 horas (2 por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 29 al 31 de mayo de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  