<template>
  <div class="container-fluid">
    
            <div class="col-12 table-title">
              Nombre del curso: Formación y actualización de auditores internos en la Norma ISO 9001:2015
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Formar auditores internos en el diseño, implementación y evaluación de un Sistema de Gestión de la Calidad de acuerdo con la norma ISO 9001:2015.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                Al finalizar el curso, los participantes:
                <ul>
                  <li>Percibirán el significado, la importancia y el objeto de formarse como auditores internos.</li>
                  <li>Estudiaran las líneas de la norma ISO 19001:2018 para efectuar auditorías internas de acuerdo con la norma ISO 9001:2015.</li>
                  <li>Entenderán el proceso, los requisitos y atribuciones de un auditor interno para implementar un Sistema de gestión de la calidad eficiente, dentro de una organización.</li>
                  <li>Desplegar las competencias y habilidades necesarias que debe tener un auditor interno.</li>
                </ul>
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Coordinador del Sistema de gestión de calidad en la Norma Internacional ISO 9001:2015, gerentes y líderes de proyecto y equipo seleccionado de auditores internos.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario: </b>
              <p>
                <ol>
                  <li>Introducción</li>
                  <ul>
                    <li>Definiciones.</li>
                  </ul>
                  <li>Bases de la auditoría</li>
                  <ul>
                    <li>Beneficios de la auditoría.</li>
                    <li>Factores críticos de éxito.</li>
                    <li>Modelo general de una auditoría.</li>
                  </ul>
                  <li>Principios de auditoría</li>
                  <ul>
                    <li>Principios de auditoría.</li>
                    <li>Principios de auditores y auditorías.</li>
                    <li>Ciclo de mejora continua PHVA.</li>
                  </ul>
                  <li>Gestión de un programa de auditoría</li>
                  <ul>
                    <li>Tipos de auditorías.</li>
                    <li>Extensión de un programa de auditorías.</li>
                    <li>Contenido de programa de auditoría.</li>
                    <ul>
                      <li>Objetivos del programa.</li>
                      <li>Determinación y evaluación de los riesgos y oportunidades.</li>
                      <li>Alcance de un programa de auditoría.</li>
                      <li>Recursos del programa de auditoría.</li>
                    </ul>
                  </ul>
                  <li>Implementación de un programa de auditoría</li>
                  <ul>
                    <li>Cómo implementar un programa de auditoría</li>
                    <li>Muestreo.</li>
                    <li>Plan de auditoría. </li>
                  </ul>
                  <li>Realización de una auditoría</li>
                  <ul>
                    <li>Preparación de las actividades de auditoría.</li>
                    <li>Muestreo.</li>
                    <li>Asignación de tareas al equipo auditor.</li>
                    <li>Realización de la reunión de apertura.</li>
                    <li>Asignación de roles y responsabilidades de las guías y los observadores.</li>
                    <li>Comunicación durante la auditoría.</li>
                    <li>Recopilación y verificación de la información.</li>
                    <li>Generación de hallazgos de auditoría.</li>
                    <li>Entrevista.</li>
                    <li>No conformidades.</li>
                    <li>Conclusiones de auditoría.</li>
                    <li>Reunión de cierre.</li>
                    <li>Preparación y distribución de informe de auditoría.</li>
                  </ul>
                  <li>Competencia y valoración de auditores</li>
                  <ul>
                    <li>Determinación de la competencia del auditor.</li>
                    <li>Comportamiento profesional.</li>
                    <li>Conocimientos y habilidades.</li>
                    <li>Métodos de evaluación del auditor.</li>
                    <li>Mantenimiento y mejora de la competencia del auditor.</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>8 días 16 horas (2 horas por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 5 al 8 y del 12 al 15 de junio de 2023
            </div>

  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  