<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Cartas de servicio (UNE 93200). Herramienta para empoderar y dialogar
          con la ciudadanía.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Cartas de servicio (UNE 93200). Herramienta para empoderar y dialogar con
      la ciudadanía.
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/servicio.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          Simplificación administrativa, reingeniería de procesos, mejora
          regulatoria, y sistema de calidad, son las primeras ideas que surgen
          en la mente del directivo que ve como la atención al público se
          deteriora todos los días en las áreas bajo su responsabilidad. Dado
          que muchas de estas estrategias ya fueron implantadas y no funcionaron
          no le queda más remedio que imitar a Nerón y empezar a tocar la lira
          mientas la oficina arde.
        </p>
        <p>
          También se tiene la idea generalizada de que todos los problemas del
          gobierno son políticos, y casi nadie se preocupa por pensar que mucho
          se resolvería atendiendo mejor a las personas que acuden a las
          oficinas públicas. No se le da ninguna oportunidad a la administración
          pública de resolver problemas.
        </p>
        <p>
          Hasta hace algunos años, para satisfacer las necesidades y las
          expectativas de la población, era suficiente con hacer una planeación,
          ejecución y evaluación de los servicios públicos en estricto apego
          normativo, esto ya es insuficiente. La nueva dinámica de interacción
          entre las ciudades ha tenido como resultado una sociedad más
          demandante que solo se satisface cuando los servicios públicos tienen
          altos estándares de calidad. Los servicios constituyen un indicador
          que es piedra angular para el desarrollo económico de una ciudad.
        </p>
        <p>
          Una de las herramientas más efectivas para mejorar, es la
          <b>carta de servicios</b> estructurada con base en la Norma UNE 93200.
          Se trata de un sistema de gestión a través del cual los entes públicos
          o privados, informan a los ciudadanos o usuarios, sobre los servicios
          que brindan, sobre los derechos que les asisten y sobre los
          compromisos de calidad en su prestación.
        </p>
        <p>
          La adopción de esta herramienta en las administraciones públicas es
          una oportunidad de mejorar su gestión, pero ¿qué tan necesaria es su
          implementación en los servicios públicos que brindan los gobiernos
          mexicanos?
        </p>
        <p>
          El propio Instituto Nacional de Estadística y Geografía (INEGI)
          realizó un estudio que mide el nivel de satisfacción con los servicios
          públicos básicos. Los servicios mejor valorados son los de educación
          pública universitaria y suministro de energía eléctrica con un 79.8 y
          77 puntos respectivamente. Otros rubros en los que también se tiene
          una puntuación “aprobatoria” son la recolección de basura, puntuada
          con 66.4 y educación pública básica con 68.2 puntos de 100 posibles.
        </p>
        <p>
          De los trece rubros valorados, son solo los cuatro anteriores los que
          tienen una puntuación “regular”, los otros servicios públicos se
          encuentran valorados de la siguiente manera: Agua potable, con 56
          puntos porcentuales; en el apartado de servicios de salud pública se
          obtuvo en el servicio de salud del ISSSTE, 47.1; servicio de salud
          estatal o seguro popular, valorado con 47.2; servicio de salud del
          IMSS, 43.8 puntos; autopistas y carreteras, 48.4; parques y jardines
          públicos, 39.7; alumbrado público, 38.2; calles y avenidas, 25.1.
        </p>
        <p>
          En este estudio llama la atención que uno de los servicios públicos
          peor valorados es el denominado “policía”, en el que se valora la
          condición de seguridad pública que se brinda y se obtuvo una
          calificación de 26.2 puntos de cien posibles.
        </p>
        <p>
          Estos datos dan cuenta de la necesidad que existe por implementar
          herramientas que permitan establecer servicios con mejores estándares
          de calidad.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>