<template>
  <div class="container-fluid">
    <h1 class="my-h1">Indicadores de Desempeño</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Indicadores de Desempeño</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Las organizaciones buscan ser más competitivas y productivas, para lograr ello deben mejorar la gestión de sus procesos y aplicar herramientas midan su gestión; el uso de indicadores permite monitorear el proceso contribuyendo a tomar decisiones a fin de lograr metas.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Los indicadores permiten; verificar si la organización va por buen camino, miden los cambios en los procesos de la organización, facilitan el análisis de los resultados obtenidos y dan seguimiento a los resultados, objetivos y meta.
      </p>
      <p>
        Los participantes aprenderán a diseñar, desarrollar e implantar un sistema de medición por indicadores en sus organizaciones para realizar ajustes necesarios en el logro de resultados planeados y entender el gran valor que un sistema de medición aporta a la competitividad.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>Características de indicadores.</li>
          <li>
            Tipos de indicadores.
          </li>
          <li>
            Pasos básicos para su contrucción.
          </li>
          <li>
            Seguimiento y medición de indicadores.
          </li>
          <li>
            Metodología para construir indicadores.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>