<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Inducción a la Norma ISO 37001:2016
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>Comprender los conceptos, enfoques, métodos y técnicas utilizadas para implementar un Sistema de Gestión Antisoborno, y</li>
                  <li>Comprender los componentes básicos de un Sistema de Gestión Antisoborno.</li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso permitirá a los participantes entender los conceptos básicos de un Sistema de Gestión Antisoborno, así como su importancia y ventajas que pueden obtener las organizaciones, la sociedad y los gobiernos.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Las personas interesadas en la Gestión Antisoborno, así como las que desean adquirir conocimientos acerca de los procesos de los Sistema de Gestión Antisoborno.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Objetivo y campo de aplicación.</li>
                  <li>Referencias normativas.</li>
                  <li>Términos y definiciones.</li>
                  <li>Contexto de la organización</li>
                  <ul class="ul-clean">
                    <li>4.1. Comprensión de la organización y su contexto.</li>
                    <li>4.2. Comprensión de las necesidades y expectativas de las partes interesadas.</li>
                    <li>4.3. Determinación del alcance del sistema de gestión antisoborno.</li>
                    <li>4.4. Sistema de gestión antisoborno.</li>
                    <li>4.5. Evaluación del riesgo del soborno.</li>
                  </ul>
                  <li>Liderazgo.</li>
                  <ul class="ul-clean">
                    <li>5.1. Liderazgo y compromiso.</li>
                    <ul class="ul-clean">
                      <li>5.1.1. Órgano de gobierno.</li>
                      <li>5.1.2. Alta dirección.</li>
                    </ul>
                    <li>5.2.  Política antisoborno.</li>
                    <li>5.3. Roles, responsabilidades y autoridades en la organización.</li>
                    <ul class="ul-clean">
                      <li>5.3.1. Roles y responsabilidades.</li>
                      <li>5.3.2. Función del cumplimiento antisoborno.</li>
                      <li>5.3.3. Delegación de la toma de decisiones.</li>
                    </ul>
                  </ul>
                  <li>Planeación.</li>
                  <ul class="ul-clean">
                    <li>6.1. Acciones para tratar riesgos y oportunidades.</li>
                    <li>6.2. Objetivos antisobornos y planificación para lograrlos.</li>
                  </ul>
                  <li>Apoyo</li>
                  <ul class="ul-clean">
                    <li>7.1. Recursos.</li>
                    <li>7.2. Competencia.</li>
                    <ul class="ul-clean">
                      <li>7.2.1. Generalidades.</li>
                      <li>7.2.2. Proceso de contratación.</li>
                    </ul>
                    <li>7.3. Toma de conciencia y formación.</li>
                    <li>7.4. Comunicación.</li>
                    <li>7.5. Información documentada.</li>
                    <ul class="ul-clean">
                      <li>7.5.1. Generalidades.</li>
                      <li>7.5.2. Creación y actuación.</li>
                      <li>7.5.3. Control de la Información documentada.</li>
                    </ul>
                  </ul>
                  <li>Operación.</li>
                  <ul class="ul-clean">
                    <li>8.1. Planificación y control operacional.</li>
                    <li>8.2. Debida diligencia.</li>
                    <li>8.3. Controles financieros.</li>
                    <li>8.4. Controles no financieros.</li>
                    <li>8.5. Implementación de los controles antisoborno por organizaciones controladas y por socios de negocios.</li>
                    <li>8.6. Compromisos antisobornos.</li>
                    <li>8.7. Regalos, hospitales, donaciones y beneficios similares.</li>
                    <li>8.8. Gestión de los controles antisoborno inadecuados.</li>
                    <li>8.9. Planteamiento de inquietudes.</li>
                    <li>8.10. Investigar y abordar el soborno.</li>
                  </ul>
                  <li>Evaluación del desempeño.</li>
                  <ul class="ul-clean">
                    <li>9.1. Seguimiento, medición, análisis y evaluación.</li>
                    <li>9.2. Auditoría Interna.</li>
                    <li>9.3. Revisión por la dirección.</li>
                    <ul class="ul-clean">
                      <li>9.3.1. Revisión por la alta dirección.</li>
                      <li>9.3.2. Revisión por el órgano de gobierno.</li>
                    </ul>
                    <li>9.4. Revisión por la función de cumplimiento antisoborno.</li>
                  </ul>
                  <li>Mejora.</li>
                  <ol>
                    <li>No conformidades y acciones correctivas.</li>
                    <li>Mejora Continua.</li>
                  </ol>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>1 día (5 horas)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>28 de abril de 2023
            </div>

  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  