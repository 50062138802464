<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          La diversidad, un nuevo reto que atender en los centros de trabajo
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">La diversidad, un nuevo reto que atender en los centros de trabajo</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/diversity.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
                <b>
                  <em>
                    Los clientes serán la palanca fundamental del cambio en la medida en que nos exijan a las organizaciones una participación igualitaria entre hombres y mujeres y diversa (de otros colectivos) en los proyectos. Pero sólo podrán exigir cuando las primeras organizaciones lo empiecen a asumir.
                  </em>
                </b>
              </p>
              <br/>
              <p>
                La ONU establece una declaratoria universal sobre la diversidad, entendida como la manifestación en la originalidad y pluralidad de identidades que caracterizan a los grupos y sociedades que componen la humanidad y, por ende, repercuten en las personas.
              </p>
              <p>
                Las sociedades actuales, requieren de mantener un equilibrio entre la diversidad y la cultura laboral, entendiendo que <b>la diversidad es una fuente de riqueza personal, social, económica, política, religiosa; por lo que se deduce que es más una realidad que una amenaza.</b>
              </p>
              <p>
                Existen mecanismos y herramientas que ayudan a que las organizaciones sean diversas e inclusivas, una de ellas es la <b>Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación</b>, formulada en 2009 a partir de la necesidad de contar con espacios de trabajo con igualdad de condiciones para hombres, mujeres y grupos de la diversidad, atendiendo, en esto último, criterios de NO DISCRIMINACIÓN.
              </p>
              <p>
                La Norma se ha ido robustecido con el paso del tiempo (2009-2015); acuñando conceptos más allá de la igualdad y la no discriminación, lo que se puede constatar en los elementos y requisitos que la componen, así como en la propia definición de lo que para efectos de la Norma significa la Diversidad:
              </p>
              <p>
                “Es la abundancia, variedad, diferencia de cosas distintas. Manifestada en la variedad de religiones, orientaciones sexuales, posturas políticas, etnias, costumbres, tradiciones, culturas, lenguas y la coexistencia misma”.
              </p>
              <p>
                La Norma considera requisitos en favor de la diversidad, entre ellos: 
                <ul>
                  <b><em></em></b>
                  <li><b><em>Contar con un proceso de reclutamiento y selección de personal sin discriminación y con igualdad de oportunidades. </em></b>El cual debe de contar con anuncios de vacantes u ofertas de trabajo expresados con lenguaje incluyente y libre de cualquier tipo de expresión discriminatoria</li>
                  <li><b><em>Existencia de un código de ética o equivalente.</em></b> Que prohíbe todo tipo de discriminación.</li>
                  <li><b><em>Contar con procesos de ascenso y permanencia con igualdad de oportunidades.</em></b> Que se cuente con procesos transparentes y accesibles de movilidad horizontal y vertical, libres de sesgos sexistas o discriminatorios.</li>
                  <li><b><em>Contar con procesos de formación, capacitación, y adiestramiento, con igualdad de oportunidades.</em></b> Que existan procesos transparentes y accesibles, libres de sesgos sexistas o discriminatorios.</li>
                  <li><b><em>Mecanismos y regulaciones para prevenir, atender y sancionar las prácticas de discriminación y violencia laboral en el centro de trabajo.</em></b> Que los mecanismos incluyan acciones para prevenir las prácticas de discriminación y violencia laboral.</li>
                </ul>
              </p>
              <p>
                Como se aprecia en el listado, son varios los requisitos y elementos que señala la Norma en atención y respeto de la diversidad, por lo que atiende este nuevo reto para las organizaciones del siglo XXI, convirtiendo a los centros de trabajo que la adoptan en espacios de oportunidades donde coexiste la diversidad entre el personal.
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>