<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Inducción a la Norma ISO 31000:2018 Sistema de Gestión de Riesgos 
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>Intuir los métodos, enfoques y técnicas que faciliten la implementación de una gestión eficaz de los riesgos en cualquier tipo de organización basado en la norma ISO 31000:2018.</li>
                  <li>Entender la aplicación de la norma ISO 31000:2018 como marco de referencia en la gestión de riesgos en una organización.</li>
                  <li>Fortificar habilidades para liderar un equipo interdisciplinar para la implementación y mantenimiento en la organización.</li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso suministra a los participantes los conocimientos, destrezas y el uso práctico necesarios para encargarse de los riesgos que se originan en una organización.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>responsables de riesgos, responsables de calidad, responsables de procesos, profesionales de la gestión y dirección organizacional, profesionales relacionados con la gestión de la calidad, licenciados y profesionales técnicos que deseen orientar su desarrollo de carrera hacia la gestión de riesgos.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ul class="ul-clean">
                  <li>
                    Tema 1. Objetivo, campo de aplicación y referencias normativas.
                  </li>
                  <li>
                    Tema 2.  Términos y definiciones. 
                  </li>
                  <li>
                    Tema 3. Principios y directrices de la gestión de riesgos.
                  </li>
                  <li>
                    Tema 4. Marco de referencia para la gestión de riesgo.
                  </li>
                  <ul class="ul-clean">
                    <li>
                      4.1. Generalidades.
                    </li>
                    <li>
                      4.2. Liderazgo y compromiso.
                    </li>
                    <li>
                      4.3. Integración.
                    </li>
                    <li>
                      4.4. Diseño.
                    </li>
                    <ul class="ul-clean">
                      <li>
                        4.4.1. Comprensión de la organización y su contexto.
                      </li>
                      <li>
                        4.4.2. Articulación del compromiso con la gestión del riesgo.
                      </li>
                      <li>
                        4.4.3. Asignación de roles, autoridades responsabilidades y obligación de rendir cuentas.
                      </li>
                      <li>
                        4.4.4. Asignación de recursos.
                      </li>
                      <li>
                        4.4.5. Establecimiento de la comunicación y la consulta.
                      </li>
                    </ul>
                    <li>
                      4.5. Implementación
                    </li>
                    <li>
                      4.6.  Valoración.
                    </li>
                    <li>
                      4.7. Mejora.
                    </li>
                    <ul class="ul-clean">
                      <li>4.7.1. Adaptación.</li>
                      <li>4.7.2. Mejora continua.</li>
                    </ul>
                  </ul>
                  <li>
                    Tema 5. Proceso para la gestión de riesgos.
                  </li>
                  <ul class="ul-clean">
                    <li>
                      5.1. Generalidades.
                    </li>
                    <li>
                      5.2. Comunicación y consulta.
                    </li>
                    <li>
                      5.3. Alcance, contexto y criterios.
                    </li>
                    <ul class="ul-clean">
                      <li>
                        5.3.1. Generalidades.
                      </li>
                      <li>
                        5.3.2. Definición del alcance.
                      </li>
                      <li>
                        5.3.3. Contextos externo e interno.
                      </li>
                      <li>
                        5.3.4. Definición de los criterios del riesgo. 
                      </li>
                    </ul>
                    <li>
                      5.4. Evaluación del riesgo.
                    </li>
                    <ul class="ul-clean">
                      <li>5.4.1. Generalidades.</li>
                      <li>5.4.2. Identificación del riesgo.</li>
                      <li>5.4.3. Análisis del riesgo.</li>
                      <li>5.4.4.  Valoración del riesgo.</li>
                    </ul>
                    <li>
                      5.5. Tratamiento del riesgo.
                    </li>
                    <ul class="ul-clean">
                      <li>5.5.1. Generalidades.</li>
                      <li>5.5.2. Selección de las opciones para la selección del riesgo.</li>
                      <li>5.5.3. Preparación e implementación de los planes de tratamiento del riesgo.</li>
                    </ul>
                    <li>
                      5.6. Seguimiento y revisión.
                    </li>
                    <li>
                      5.7. Registro e informe.   
                    </li>
                  </ul>
                </ul>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>20 horas. 5 días (4 horas por día) 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 19 al 23 de junio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  