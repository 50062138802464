<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Equidad o igualdad
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Equidad o igualdad</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/gender.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <div class="d-flex justify-content-center">
          <b>Equidad de género e igualdad de género. ¿Son lo mismo?</b>
        </div>
        <div class="d-flex justify-content-end">
          <b>Ramón Cuevas Martínez</b>
        </div>
        <br />
        <p style="text-align: center">
          <b>
            <em>
              “La primera igualdad es la equidad”. Víctor Hugo, escritor
              francés.
            </em>
          </b>
        </p>
        <br />
        <p>
          La comprensión correcta de temas como igualdad y equidad, en el
          contexto laboral, y también en el personal, son de las habilidades
          blandas que más necesitan en este momento los centros de trabajo, dado
          que se trata de destrezas asociadas a la
          <b>inteligencia emocional</b> y a la capacidad que tiene un individuo
          para interactuar efectivamente a nivel personal y profesional.
        </p>
        <p>
          El dominio de estas habilidades blandas es transversal a las
          habilidades duras, que se refieren a la capacidad de tener
          conocimientos útiles para el mercado laboral, derivados de su
          formación profesional, del trabajo que tienen a su cargo, o del oficio
          que practican. Las habilidades blandas soportan el adecuado
          relacionamiento entre empleados y patrones, por eso juegan un papel
          importante en la productividad.
        </p>
        <p>
          El ejemplo más claro que pondremos para mostrar que es la equidad
          proviene del mundo de la aplicación de la ley, digamos que la equidad
          es la capacidad de administrar justicia de forma imparcial, pero
          considerando las particularidades de cada caso para evitar sanciones
          poco equitativas o injustas.
        </p>
        <p>
          La diferencia entre igualdad de género y equidad de género es que la
          primera <b>(igualdad)</b> es un principio que reconoce que las
          necesidades y características de mujeres y hombres son valoradas y
          tomadas en cuenta de la misma manera, mientras que la
          <b>equidad</b> es una consideración de carácter ético con la que se
          intentan aplicar medidas que corrijan el desequilibrio histórico entre
          los géneros. Podría decirse que la igualdad de género es el ideal de
          la sociedad del siglo XXI, mientras que la equidad de género se define
          por las acciones que se llevan a cabo todos los días para garantizar
          el cumplimiento de ese derecho.
        </p>
        <p>
          La equidad de género se presenta como una herramienta para lograr la
          igualdad real en el goce de derechos, reconociendo la desigualdad
          existente en la estructura social y laboral, a fin de revertirla y
          convertirla en el derecho que todas y todos tenemos, es decir, LA
          IGUALDAD.
        </p>
        <p>
          Un plan de acciones afirmativas con perspectiva de equidad de género
          contribuye decididamente a incrementar la participación de las mujeres
          en el mercado laboral, en el acceso a la educación, y en la
          disminución de cualquier tipo de violencia.
        </p>
        <p>
          En resumen, lo que parece simplemente una discusión sobre terminología
          irrelevante, es sustancial para estar en condiciones de alcanzar la
          igualdad en tres aspectos: el derecho a la igualdad y no
          discriminación, para identificar la obligación que tienen los
          gobiernos respecto a la igualdad, y para lograr la igualdad
          sustantiva.
        </p>
        <p>
          Construir una sociedad con igualdad de derechos y oportunidades para
          mujeres y hombres ha implicado esfuerzos y acciones a lo largo de
          muchos años, la igualdad no es solo un estado social que debería
          perpetuarse, también es una cuestión de salud y bienestar, y un
          inestimable motor de crecimiento económico, además de representar uno
          de los pilares de cualquier política pública de desarrollo.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>