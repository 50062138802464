<template>
  <div class="container-fluid">
    <h2 class="my-h2">Servicio de implantación</h2>
    <h3 class="my-h3">Desarrollamos los siguientes procedimientos</h3>
    <div class="general-text">
      <ol>
        <li>Constitución de un equipo de trabajo.</li>
        <li>Evaluación del nivel actual del servicio.</li>
        <li>
          Diseño de encuestas y coordinación de <b>focus group</b> para la
          identificación de las expectativas de los usuarios.
        </li>
        <li>Formulación de compromisos e indicadores de calidad.</li>
        <li>Identificación de los procesos ligados a los servicios.</li>
        <li>Diseño de medidas de subsanación y compensación.</li>
        <li>
          Diseño de mecanismos eficaces para la difusión de las cartas de
          servicio.
        </li>
        <li>
          Comparación del nivel de prestación de los servicios con las
          expectativas del usuario.
        </li>
        <li>Redacción de la carta de servicios.</li>
      </ol>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "ServicioDeImplantacionView",
};
</script>
        
<style>
</style>