<template>
  <div class="container-fluid">
    <h2 class="my-h2">En que consiste</h2>
    <div class="general-text">
      <p>
        Proporciona reglas para gestionar el riesgo al que se enfrentan las
        organizaciones. La aplicación de estas directrices puede adaptarse a
        cualquier organización sea cual sea su tamaño y contexto.
      </p>
      <p>
        Provee un enfoque común para gestionar cualquier tipo de riesgo y no es
        específico de una industria o un sector, puede utilizarse a lo largo de
        la vida de la organización y puede aplicarse a cualquier actividad,
        incluyendo la toma de decisiones a todos los niveles.
      </p>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "EnQueConsisteView",
};
</script>
      
<style>
</style>