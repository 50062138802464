<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Empresas tóxicas
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Empresas tóxicas</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/company.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
                <b>
                  <em>
                    ¡A usted no le pagan para pensar!,<br />
                    frase tóxica escuchada siempre en un centro de trabajo
                    tóxico.
                  </em>
                </b>
              </p>
              <p>
                La pandemia deterioró las relaciones laborales en todo el mundo, prueba de ello es la información que nos comparte Xavier Marcet al puntualizar que entre abril y septiembre de 2021, 24 millones de trabajadores dejaron su empleo voluntariamente en EEUU, cifra que equivaldría a que un poco menos de la mitad de la Población Económicamente Activa de México (55 millones), decidiera de un día para otro emigrar a nuevas aventuras laborales. Será hasta el cierre de 2022 cuando conoceremos la afectación a las metas de productividad de las empresas y del país que estamos padeciendo.
              </p>
              <p>
                En lo que respecta a México, según una investigación de la UNAM el 85% de las organizaciones no tienen las condiciones adecuadas para que sus colaboradores (as) disfruten de un sano balance entre la vida laboral, familiar y personal, lo que incrementa los trastornos físicos y psicológicos en todos los niveles organizacionales.
              </p>
              <p>
                A nivel mundial México se encuentra entre los primeros lugares con el mayor número de personas con estrés laboral, así lo expusieron Erika Villavicencio Ayub y Gladys Martínez Santiago, académicas de las facultades de Psicología (FP) y Medicina (FM) de la UNAM, respectivamente.
              </p>
              <p>
                Ellas señalaron que el <b>85% de las organizaciones en el país son tóxicas</b>, es decir, no cuentan con las condiciones adecuadas para el desempeño de sus trabajadores, no cuidan a su talento humano y promueven diferentes trastornos como el estrés, padecimiento relacionado con la adicción al trabajo, el síndrome burnout, acoso laboral y “presentismo”, entre otros.
              </p>
              <p>
                Esta situación afecta a ambas partes, empresa y trabajadores. Para las organizaciones se traduce en mayor costo económico, pues implica baja productividad, incremento de accidentes de trabajo, aumento del ausentismo, pero también del “presentismo” (ir al centro laboral, pero no trabajar).
              </p>
              <p>
                Las empresas tóxicas tienen el común denominador de no generar un ambiente de respeto, lo que da como consecuencia que las personas toxicas hagan su festín todos los días. Es por eso que vemos que controlar, gritar y manotear son características de las relaciones de trabajo diarias en este tipo de centros de trabajo.
              </p>
              <p>
                Desterrar la toxicidad en las personas y en las organizaciones no es fácil, requiere conocer y aplicar mecanismos de gestión que ayuden a generar un clima de respeto y colaboración, al menos existen dos herramientas básicas:
                <ol>
                  <li>Contar con sistema de gestión en igualdad laboral y no discriminación que ayude a que las relaciones laborales se centren en generar igualdad de oportunidades para hombres y mujeres, y a combatir frontalmente la violencia laboral</li>
                  <li>
                    Que se identifiquen, clasifiquen y atiendan los factores de riesgo psicosocial, para evitar que las enfermedades del trabajo sigan afectando la salud física y emocional de todos los empleados. 
                  </li>
                </ol>
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>