<template>
  <div class="container-fluid">
    <h1 class="my-h1">Mapeo de Procesos</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Mapeo de Procesos</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        El mapeo de procesos es una herramienta analítica y de comunicación probada que pretende ayudarte a mejorar los procesos existentes o implementar una nueva estructura dirigida por procesos.
      </p>
      <p>
        La estructura y procesos internos, para que una entidad organizacional tenga éxito en el cumplimiento de sus funciones, objetivos, fines, compromisos y expectativas necesita identificar, entender y conocer los procesos de negocio para definir los futuros con el objetivo de mejorar el nivel de satisfacción de clientes y empleados, así como mejorar la calidad de los productos o servicios, reduciendo costes y aumentando el aprovechamiento de los insumos y el rendimiento del negocio en el funcionamiento general de la organización.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        El mapeo de procesos consiste en una actividad de diagnóstico organizacional. Con él, tu organización entiende cómo se desarrollan sus actividades, cuáles son las interdependencias entre sectores, cuáles son las fallas recurrentes y así crear un plan de acción para la mejora de los procesos y su consecuente aumento de productividad y eficiencia.
      </p>
      <p>
        Permite una comprensión clara de los procesos:
        <ul>
          <li>
            Analiza el proceso de tu organización a fin de
            buscar su mejora.
          </li>
          <li>
            Define los requisitos más fáciles a fin de
            simplificar la gestión del sistema.
          </li>
          <li>
            Reduce el tiempo de realización de los procesos.
          </li>
          <li>
            Lograr la normalización en los procesos y la
            mejora de la calidad.
          </li>
          <li>
            Introducir el cambio de un pensamiento basado en
            departamentos a uno basado en procesos.
          </li>
          <li>
            Identificar y entender necesidades presentes y
            futuras de los clientes.
          </li>
          <li>
            Involucramiento de la gente y que estas conozcan
            sus funciones.
          </li>
          <li>
            Administrar los recursos y las actividades como
            procesos.
          </li>
          <li>
            Detectar ventajas competitivas.
          </li>
        </ul>
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Calidad total.
          </li>
          <li>
            Herramientas de calidad.
          </li>
          <li>
            Beneficios del mapeo de procesos.
          </li>
          <li>
            Criterios de selección de procesos.
          </li>
          <li>
            Tipos de procesos.
          </li>
          <li>
            Niveles de descripción de procesos.
          </li>
          <li>
            Metodologías para el mapeo de procesos.
          </li>
          <li>
            Elaboración del mapeo de procesos.
          </li>
          <li>
            Uso del mapeo de procesos para el proceso de
            mejora.
          </li>
          <li>
            Mitos y Realidades de los Procesos.
          </li>
          <li>
            Diferencia entre una Reingeniería y un Mapeo de
            Procesos.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>