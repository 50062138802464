<template>
  <div class="container-fluid">
    <img
      src="@/assets/img/Introduccion_a_la_norma_intermacional_ISO_37001.jpg"
      class="d-block w-100"
      alt="..."
    />
  </div>
</template>

<script>
export default {
  name: "CursoPage",
};
</script>

<style>
</style>