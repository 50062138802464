<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Programa de implantación exitosa de un sistema de gestión electoral
          bajo la norma ISO 54001
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Programa de implantación exitosa de un sistema de gestión electoral bajo
      la norma ISO 54001
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/waiting_to_vote.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          Como todas las normas ISO que llegan a nuestro país, actualmente la
          54001 se encuentra en proceso de consulta pública por parte de la
          Secretaría de Economía, como instancia responsable del Sistema
          Nacional de Metrología y Normalización., por lo que pronto tendremos
          una nueva norma mexicana “no obligatoria”, que se pondrá a disposición
          de las organizaciones electorales que quieran mejorar su desempeño.
        </p>
        <p>
          También significa que pronto surgirán los primeros organismos de
          certificación autorizados por la Entidad Mexicana de Acreditación
          (EMA), para realizar auditorías que constaten la debida implantación
          de todos los requisitos de un sistema de gestión electoral. Conozco
          muchos expertos electorales que deben aprovechar la posibilidad de
          formarse como auditores electorales, hay pocos en México.
        </p>
        <p>¿Qué es ISO 54001?</p>
        <p>
          Es una guía de aplicación para lograr incrementar la confianza en la
          gestión de los organismos que administran y califican elecciones, a la
          vista de todos los usuarios de servicios electorales. Se debe precisar
          que, para lograr la adecuada implantación, la norma electoral requiere
          de la existencia previa de un sistema de gestión de la calidad basado
          en la Norma ISO 9001:2015, dado que la 54001 agrega requisitos de
          cumplimiento electoral. Va un ejemplo:
        </p>
        <p>
          La Norma ISO 9001 señala que todo programa de implantación en
          cualquier organización debe incorporar los siguientes procesos: Los
          directivos, el de compras, el de recursos humanos, los que incluyen la
          prestación del servicio, y los de evaluación interna. La 54001
          puntualiza que los procesos electorales de prestación de servicios a
          incorporar obligatoriamente, además de los arriba señalados, son:
        </p>
        <p>
          1. Inscripción Electoral, 2. Inscripción de organizaciones políticas y
          de candidatos, 3. Logística electoral, 4. Emisión del voto, 5.
          Escrutinio y declaración de resultados, 6. Educación electoral, 7.
          Fiscalización del financiamiento de campañas electorales, y 8.
          Resolución de conflictos electorales.
        </p>
        <p>
          Una vez que se cuente con el cumplimiento de los requisitos
          solicitados para cada uno de los procesos, la organización electoral
          deberá someterse a un programa de auditorías internas, realizado por
          el propio personal de la institución, a efecto de para verificar que
          todo se realiza conforme a lo declarado, y, sobre todo, que se realiza
          (el principal problema de un sistema mal implantado es la simulación),
          lo que demostrará que la organización electoral está preparada para
          recibir una auditoría de certificación, ya sea por un organismo
          internacional (en este punto se recomienda contactar a la Organización
          de Estados Americanos, pues ellos impulsaron la 54001 desde su inicio
          en 2010, -antes ISO/TS 17582:2014- y cuenta con el mayor número de
          auditores certificados), o bien, también se puede contactar a algún
          organismo certificador mexicano, que para entonces contará con
          auditores electorales, avalados por su competencia y experiencia,
          mediante un riguroso mecanismo de selección autorizado por la EMA.
        </p>
        <p>
          Por tratarse de una Norma que abarca procesos que no solo se
          encuentran en un organismo de administración electoral, como por
          ejemplo el INE, puesto que la 54001 incluye calificación electoral,
          que en el caso mexicano corresponde a los tribunales electorales, se
          debe informar en el proceso de auditoría externa que dicha tarea no
          forma parte de las atribuciones del organismo. En este sentido se abre
          la posibilidad también de que cualquier Tribunal Electoral opte por la
          certificación con solamente el proceso de calificación electoral, dado
          que es objeto de su competencia.
        </p>
        <p>
          Un caso similar sucede con las juntas distritales electorales, quienes
          también pueden optar por la certificación considerando los requisitos
          que les son aplicables.
        </p>
        <p>
          En México afortunadamente el Instituto Electoral de la Ciudad de
          México tiene varios años trabajando con su sistema de gestión de
          calidad electoral, por lo que convierte en una referencia de buenas
          prácticas para aquellos institutos y tribunales que se quieran
          adentrar en el maravilloso mundo de la calidad electoral.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>