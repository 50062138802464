<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Herramientas de calidad y mejora continua
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Conocer y entender las herramientas de planificación avanzada de la calidad del servicio electoral y planes de control; procesos de aprobación de segmentos de producción; análisis modal de fallos y efectos; control estadístico del proceso y análisis de los sistemas de medición.
              </p>
              <p>
                Saber elegir y aplicar la mejor herramienta de gestión para cumplir con los requisitos de los Sistemas de Gestión de Calidad electoral de acuerdo con la NMX-CC-54001-IMNC-2020 y aprovechar al máximo las capacidades que ofrecen las herramientas de gestión de la calidad en un sistema integrado al servicio. 
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                Los asistentes identificarán las mejores herramientas de servicio para efectuar sus funciones aprovechando al máximo sus capacidades que le ofrezca un sistema de gestión de calidad electoral. 
              </p>
              <p>
                El curso se adaptará a las necesidades de cada usuario, la enseñanza será interactiva con ejercicios prácticos y/o dinámicas participativas.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Empleados de los organismos electorales encargados de implementar la mejora continua, así como a los directivos y profesionales que deseen aplicar herramientas de calidad y mejora continua. 
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li><u>Modelo 5S</u> para el “Mantenimiento Integral” implicando a todo el personal.</li>
                  <li><u>SIX Sigma</u> para la mejora de procesos.</li>
                  <li>Las siete herramientas de la calidad:</li>
                  <ol type="a">
                    <li>Diagrama Causa - Efecto (Diagrama de Ishikawa)</li>
                    <li>Hoja de Comprobación (Hojas de Verificación).</li>
                    <li>Diagrama de Pareto, para ayudar a establecer prioridades.</li>
                    <li>Estratificación.</li>
                    <li>APQP (Planificación Avanzada de la Calidad de los Productos y Planes
                    de Control)</li>
                    <li>PPAP (Proceso de Aprobación de Piezas para Producción</li>
                    <li>FMEA (Análisis Modal de Fallos y Efectos)</li>
                    <li>SPC (Control Estadístico del Proceso): Gráficos de Control; Histograma; Diagrama de Dispersión</li>
                    <li>MSA (Análisis de los Sistemas de Medición)</li>
                  </ol>
                  <li>Método Kaizen para implantar una mejora continua eficiente y eficaz.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>1 día 3 horas
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>10 de agosto de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  