<template>
  <div class="container-fluid">
    <h2 class="my-h2">Servicio de implantación</h2>
    <h3 class="my-h3">2.	Metodología</h3>
    <div class="general-text">
      <p>
        Fase 1. Diagnóstico a Procesos Clave;
        <ol type="a">
          <li>Fase 2. Reunión con Alta Autoridad;</li>
          <li>Fase 3. Capacitación y Entrenamiento;</li>
          <li>Fase 4. Despliegue, Difusión y Adecuación de Planes Estratégicos;</li>
          <li>Fase 5. Diseño y Estructuración del Sistema de Gestión;</li>
          <li>Fase 6. Desarrollo e Implementación de la Documentación del Sistema de Gestión Electoral;</li>
          <li>Fase 7. Preauditoría de Certificación; y</li>
          <li>Fase 8. Auditoría de Certificación.</li>
        </ol>
      </p>
      <p>
        Identificamos las actividades internas y externas que afectan la capacidad de lograr resultados previstos en el sistema de gestión electoral.
        <ul>
          <li>Planificamos el sistema de gestión en todo el proceso electoral.</li>
          <li>Examinamos y revisamos la normatividad legal que regula el funcionamiento de las organizaciones y procesos electorales.</li>
          <li>Ajustamos los asuntos económicos y presupuestarios que influyen en el desarrollo del servicio electoral.</li>
          <li>Brindamos cuestiones relacionadas con los materiales electorales, especialmente tecnológicos.</li>
          <li>Apoyo a la alta dirección para demostrar liderazgo.</li>
          <li>Implementamos políticas de calidad.</li>
          <li>Determinamos los riesgos y oportunidades de la organización.</li>
          <li>Evaluación de la eficacia de las acciones.</li>
          <li>Establecemos objetivos de calidad.</li>
          <li>Control de procesos.</li>
          <li>Conservación de información documentada.</li>
          <li>Auditoría interna.</li>
          <li>Resolución de conflictos.</li>
          <li>Mejora continua.</li>
        </ul>
      </p>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "ServicioDeImplantacionView",
};
</script>
        
<style>
</style>