<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          ¿CÓMO VAMOS CON LA IGUALDAD SALARIAL?
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">¿CÓMO VAMOS CON LA IGUALDAD SALARIAL?</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/igualdad_salarial.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          <b>
            La realidad es que, si nos quedamos de brazos cruzados, nos llevará
            75 años o hasta que cumpla 100 que las mujeres ganen lo mismo que
            los hombres por el mismo trabajo.<br />Emma Watson, actriz
          </b>
        </p>
        <p>
          De acuerdo con estadísticas de la Organización de las Naciones Unidas
          (ONU), en 2017 las tasas de desempleo de mujeres y hombres en América
          Latina y el Caribe eran del 10,4% y el 7,6%, respectivamente. Casi un
          tercio de las mujeres de la región no contaban con ingresos propios,
          situación que las hace altamente vulnerables y económicamente
          dependientes.
        </p>
        <p>
          En 2017, el promedio de mujeres sin ingresos propios alcanzó un 29,4%,
          mientras que el de los hombres fue del 10,7 por ciento. Además, las
          encuestas de uso del tiempo en 18 países de América Latina y el Caribe
          mostró que las mujeres dedicaban entre un quinto y un tercio de su
          tiempo al trabajo doméstico y de cuidados no remunerado; mientras, en
          el caso de los hombres esta proporción es de alrededor de un décimo.
        </p>
        <p>
          Por su parte, la Organización Internacional del Trabajo (OIT), en su
          Informe Mundial sobre Salarios 2018-2019 señala que, a escala mundial,
          las mujeres siguen percibiendo un salario aproximadamente 20% inferior
          al de los hombres. En el caso de México, la brecha es del 15.6 por
          ciento
        </p>
        <p>
          Cerrar esta brecha salarial de género requiere de un conjunto de
          medidas que promuevan el trabajo decente para todas las personas. Esto
          incluye medidas que promuevan la formalización de la economía informal
          y protejan jurídica y efectivamente a quienes trabajan de manera
          informal, y les empoderen para defender mejor sus intereses.
        </p>
        <p>
          En México, el pasado 11 de marzo, el Senado de la República, con 113
          votos a favor y 0 en contra, aprobó una serie de reformas a diversos
          ordenamientos jurídicos, con lo cual se busca garantizar las
          condiciones de remuneración equitativa para mujeres y hombres, es
          decir, que los salarios sean en función de la responsabilidad que
          demande un puesto, sin que el sexo sea un factor que determine mayor o
          menor ingreso.
        </p>
        <p>
          Tal y como fue expresado por la propia OIT, esta serie de ajustes
          normativos representan un parteaguas en la manera que se atiende el
          problema de desigualdad de género en el país, pues esta vez se dejan
          los discursos de lado y se presenta una acción que no solo es
          concreta, sino también sistemática, pues para poder ser puesta en
          práctica, requiere de una transversalidad conjunta; primero con otras
          dependencias como es el caso de la Secretaría del Trabajo y Previsión
          Social (STPS); y segundo, obliga a las esferas, pública y privada, a
          respetar estas disposiciones.
        </p>
        <p>
          Con estas disposiciones poco a poco se va logrando una igualdad
          sustantiva de género, pues cada vez son más las herramientas que se
          ponen en práctica para alcanzarla. Es importante precisar que la
          existencia de una buena práctica no sustituye a otras que ya se venían
          trabajando; al ser un problema tan complejo, la desigualdad laboral,
          se debe trabajar desde varios frentes, por lo que las herramientas
          prácticas deben aplicarse de manera conjunta.
        </p>
        <p>
          Un ejemplo claro de esto sería que, paralelamente a impulsar la nueva
          normatividad sobre igualdad salarial, se sigan implementando con mayor
          apoyo y fortaleza aquellos mecanismos que ha probado ser efectivos en
          consolidar centros de trabajo (públicos, privados o sociales) con
          igualdad laboral.
        </p>
        <p>
          Mención especial merece la Norma Mexicana NMX R 025 SCFI 2015 en
          Igualdad Laboral y No Discriminación, ya que dentro de sus requisitos
          de certificación contempla elementos fundamentales como: procesos de
          reclutamiento y selección de personal sin discriminación y con
          igualdad de oportunidades; garantiza la igualdad salarial y
          otorgamiento de prestaciones y compensaciones al personal; desarrolla
          procesos de ascenso y permanencia con igualdad de oportunidades; y
          hace obligatoria la existencia de acciones para la corresponsabilidad
          en la vida laboral, familiar y personal con igualdad de oportunidades.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>