<template>
  <div class="container-fluid">
    <!--Animated title-->
    <div class="animate-div">
      <p class="animate__animated animate__slideInDown">Nosotros</p>
    </div>
    <!--Animated title end-->
    <div class="row mb-5 mt-1">
      <div class="col-lg-6 py-2">
        <!--card MISIÓN-->
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">Misión</h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Ofrecer a organizaciones públicas, privadas o sociales soluciones
              para optimizar, estandarizar, capacitar y dar mantenimiento a sus
              procesos internos de trabajo, a través de la implantación de
              sistemas de gestión.
            </p>
          </div>
        </div>
        <!--card MISIÓN end-->
      </div>
      <div class="col-lg-6 py-2">
        <!--card VISIÓN-->
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">Visión</h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Consolidarnos como una empresa líder en México, con los más altos
              estándares de calidad en la implantación de sistemas de gestión.
            </p>
          </div>
        </div>
        <!--card VISIÓN end-->
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "NosotrosPage",
};
</script>
  
<style>
</style>