<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Mapeo de procesos para el desarrollo de objetivos
            en las organizaciones electorales
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Conocer técnicas y herramientas para efectuar el mapeo de los procesos que integran su organización electoral.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                Al finalizar el curso, los asistentes comprenderán las técnicas y herramientas para llevar a cabo el mapeo de los procesos en su organización electoral, identificando como se realizan los trabajos actualmente en ella mediante el análisis de los pasos del proceso para reducir el ciclo de tiempo y aumentar la calidad.
              </p>
              <p>
                Asimismo, los participantes analizaran si la estructura y las funciones de la organización son fieles a las tareas que efectúa cada persona estandarizada las actividades mediante el mapeo con la finalidad de comprender que todos pueden entender la organización y asumir sus funciones de una manera uniforme para que el usuario siempre reciba el mismo servicio sea quien sea el que se lo preste. 
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>La alta dirección, jefes y coordinadores de calidad, responsables de producción, mantenimiento, recursos humanos, almacenes, compras y calidad, personal involucrado en la implementación de los sistemas de gestión de calidad y miembros del equipo de auditores internos.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Introducción</li>
                  <ul>
                    <li>Definición de proceso </li>
                    <li>Elementos de un proceso</li>
                    <li>Enfoque de procesos, beneficios</li>
                    <li>Medición y seguimiento de los procesos</li>
                  </ul>
                  <li>Los procesos en un sistema productivo</li>
                  <ul>
                    <li>Identificación</li>
                    <li>Procesos directivos</li>
                    <li>Procesos productivos</li>
                    <li>Procesos de soporte</li>
                    <li>Interacción de los procesos</li>
                  </ul>
                  <li>Requisitos de los procesos</li>
                  <ul>
                    <li>Responsables de procesos</li>
                    <li>Actividades dentro de los procesos</li>
                    <li>Recursos necesarios para los procesos</li>
                    <li>Información documentada</li>
                    <li>Los criterios de control en los procesos</li>
                    <li>Seguimiento y mejora de los procesos</li>
                    <li>Riesgos y oportunidades en los procesos</li>
                  </ul>
                  <li>Las entradas al proceso </li>
                  <ul>
                    <li>Identificación</li> 
                    <li>Diferencia con los recursos necesarios para los procesos</li> 
                    <li>Los criterios de control en las entradas</li> 
                    <li>Métricas y seguimiento de los criterios de control</li> 
                    <li>Mejora</li> 
                    <li>Fuentes generadoras de las entradas a los procesos</li> 
                  </ul>
                  <li>Las salidas del proceso </li>
                  <ul>
                    <li>Identificación</li> 
                    <li>Criterios de control de las salidas del proceso</li> 
                    <li>Métricas y seguimiento al cumplimiento de criterios de control</li> 
                    <li>Riesgos y oportunidades de las salidas de los procesos</li> 
                    <li>Mejora.</li>
                  </ul>
                  <li>El mapa de procesos</li>
                  <ul>
                    <li>Como requisito de normas</li> 
                    <li>Beneficios y aplicaciones</li> 
                    <li>Riesgos y oportunidades identificables en los mapas de procesos.</li> 
                    <li>Como herramienta de auditorías</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>2 días 8 horas
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>1 y 2 de agosto de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  