<template>
  <div class="container-fluid">
    
            <div class="col-12 table-title">
              Nombre del curso: Interpretación a la Norma ISO 9001:2015
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Al analizar el curso, el participante conocerá los principios y
                prácticas de un sistema de gestión de calidad basado en la Norma
                Internacional ISO 9001:2015, así como las etapas y estrategias
                de implementación.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                La finalidad del curso consistirá en enseñar a los participantes
                los requisitos del contexto de una organización en la Norma
                Internacional ISO 9001:2015 a efecto que sepan cómo aplicarlos
                para la implantación de un Sistema de gestión de calidad
                eficiente.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a:</b> La alta dirección, jefes, gerentes y personal
                encargado en una organización de la calidad, producción,
                almacén, compras, mantenimiento, así como personal encargado de
                la implantación del sistema de gestión de calidad, y auditores
                internos.
              </p>
              <p>
                <b>Garantía de satisfacción:</b> Avalamos la transmisión de
                conocimientos a través de una metodología que permita elegir la
                información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia:</b> Al término del curso se otorgará una
                constancia de participación con valor curricular, con la
                insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Contexto de la organización</li>
                  <ul>
                    <li>Compresión de la organización y su contexto.</li>
                    <li>Comprensión de las necesidades y expectativas de las partes interesadas.</li>
                    <li>Determinación del alcance del Sistema de Gestión de Calidad.</li>
                    <li>Sistema de Gestión de Calidad y sus procesos.</li>
                  </ul>
                  <li>Liderazgo</li>
                  <ul>
                    <li>Liderazgo y compromiso.</li>
                    <li>Política.</li>
                    <li>Roles, responsabilidades y autoridades en la organización.</li>
                  </ul>
                  <li>Planificación</li>
                  <ul>
                    <li>Acciones para abordar los riesgos y las oportunidades. </li>
                    <li>Objetivos de calidad y planificación para lograrlos.</li>
                    <li>Planificación de los cambios.</li>
                  </ul>
                  <li>Soporte</li>
                  <ul>
                    <li>Recursos.</li>
                    <li>Competencia.</li>
                    <li>Concienciación.</li>
                    <li>Comunicación.</li>
                    <li>Información documentada.</li>
                  </ul>
                  <li>Operación</li>
                  <ul>
                    <li>Planificación y control operacional.</li>
                    <li>Requisitos para los productos y servicios.</li>
                    <li>Diseño y desarrollo de los productos y servicios.</li>
                    <li>Control de los procesos, productos y servicios suministrados externamente.</li>
                    <li>Producción y provisión del servicio.</li>
                    <li>Liberación de los productos y servicios.</li>
                    <li>Control de las salidas no conformes.</li>
                  </ul>
                  <li>Evaluación Desempeño</li>
                  <ul>
                    <li>Seguimiento, medición, análisis y evaluación.</li>
                    <li>Auditoría interna.</li>
                    <li>Revisión por la dirección.</li>
                  </ul>
                  <li>Mejora</li>
                  <ul>
                    <li>Generalidades.</li>
                    <li>No conformidad y acción correctiva.</li>
                    <li>Mejora continua.</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración:</b> 8 días 16 horas (2 horas por día)
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 3 al 6 y del 10 al 13 de abril de 2023
            </div>

  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  