<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Violencia Laboral
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Violencia Laboral</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/anxiety.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          <b>
            <em>
              ¿Acaso no haces nada bien?, ¡Qué sea la última vez que...!,
              ¡Tienes suerte de trabajar aquí, en otros lados…!, ¡No es mi
              problema!, ¡Para eso te pago!, ¡El que manda soy yo!, ¡No te pago
              por pensar, te pago por hacer!...
            </em>
          </b>
        </p>
        <br />
        <p>
          Las últimas modificaciones a la ley Federal del Trabajo, incorporan la
          palabra TRABAJO DIGNO, lo cual quiere decir que se respeta plenamente
          la dignidad humana del trabajador; no existe discriminación por origen
          étnico o nacional, género, edad, discapacidad, condición social,
          condiciones de salud, religión, condición migratoria, opiniones,
          preferencias sexuales o estado civil; se tiene acceso a la seguridad
          social y se percibe un salario remunerador.
        </p>
        <p>
          Por otro lado, la violencia laboral consiste en tomar una serie de
          <b>acciones en contra de otro individuo en el entorno laboral</b>, las
          cuales pueden ser ejercidas tanto por una figura de autoridad como por
          uno o más compañeros de trabajo del mismo rango.
        </p>
        <p>
          Se trata de un fenómeno que se repite con gran frecuencia en diversos
          espacios de trabajo. La violencia laboral además de conllevar el abuso
          de poder, también implica la violación de un conjunto de derechos
          laborales y humanos que son fundamentales para el bienestar y
          desarrollo de los individuos. En este sentido, la violencia laboral
          también
          <b
            >puede incluir violencia de género, así como acoso sexual,
            psicológico o moral.</b
          >
        </p>
        <p>
          La reforma a la Ley Federal del Trabajo en el artículo 132, fracción
          XXXI, establece como obligación patronal poner en marcha un protocolo
          para prevenir la discriminación por razones de género y atención de
          casos de violencia, acoso u hostigamiento sexual.
        </p>
        <p>
          El protocolo es un mecanismo efectivo para la adecuada gestión interna
          del problema, ya que pone especial énfasis en la violencia de género
          contra las mujeres, al tiempo que constituye un instrumento para
          fortalecer la igualdad y no discriminación en beneficio del personal.
          En el ámbito laboral, el 39% de las 55.7 millones de personas ocupadas
          en México son mujeres que desarrollan su trabajo en una atmósfera de
          violencia en su contra.
        </p>
        <p>¿Qué hacer para para disminuir la violencia laboral?</p>
        <p>
          Es urgente contar con protocolos para erradicar este tipo de
          violencia, ya que contienen mecanismos, estrategias y acciones
          debidamente sistematizadas por especialista en la materia. Por
          ejemplo, si los centros de trabajo se encuentran implementando la
          Norma NMX 025 en Igualdad Laboral y No Discriminación, inmediatamente
          entran en operación mecanismos y regulaciones para prevenir, atender y
          sancionar las prácticas de discriminación y violencia laboral de
          acuerdo con la naturaleza de la violencia que se ejerce.
        </p>
        <p>
          Un protocolo de esta naturaleza funciona con actores y acciones
          estratégicas dentro de la organización, destacándose un conjunto de
          figuras de autoridad como una persona consejera, un comité de
          atención, seguimiento y canalización, acorde con el tipo de violencia
          que se ejerce; y entre las acciones estratégicas se consideran medidas
          de protección a las víctimas, medidas y sanciones para modificar el
          comportamiento de agresores/agresoras, para dar solución a las
          víctimas y acciones de difusión del mecanismo (protocolo).
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>