<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del Curso: Inducción a la Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación.
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>
                    Aprender los preceptos básicos, principios y prácticas de la Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación, permitiendo al asistente contar con los conocimientos y habilidades necesarias para la implantación de la Norma en un centro de trabajo.
                  </li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso permite a los participantes conocer los conceptos básicos de la Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación, para garantizar que con la implantación de esta se cuente con un Centro de Trabajo libre de violencia, discriminación, tener mejores oportunidades de trabajo, trabajos dignos, así como un equilibrio entre la vida laboral y la familiar.
              </p>
              <p>
                Además, identificarán los beneficios de fomentar los derechos humanos y laborales, al sensibilizar y aumentar la conciencia social en materia de igualdad laboral y no discriminación, lo que permitirá atraer, desarrollar, motivar y retener a los mejores talentos; así como dar un mejor servicio e incrementar la productividad; consolidando una cultura empresarial con sensibilidad de género, fortaleciendo la pertenencia, permanencia y compromiso del personal.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Profesionistas, independientes, consultores, académicos, estudiantes, la alta dirección y personal de los centros de trabajo públicos, privados y sociales que estén interesados en implantar o renovar la Norma Mexicana NMX-R-025-SCFI-2015.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Antecedentes de la Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación.</li>
                  <li>Marco jurídico.</li>
                  <li>Objetivo y campo de aplicación de la Norma.</li>
                  <li>Definición Generales.</li>
                  <li>Beneficios de implantar la Norma de Igualdad Laboral y No Discriminación.</li>
                  <li>Buenas prácticas en la implantación de la Norma.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>1 día 4 horas 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>31 de julio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  