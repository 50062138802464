<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Generación y gestión de las cartas de servicio y
            certificación de compromisos conforme a la Norma UNE 93200:2008
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Conocer los ciclos de vida, el proceso metodológico de elaboración, aprobación, la gestión dinámica y sistemas de gestión de las cartas de servicio, así como las formas de certificación de los compromisos en estas en las organizaciones de la administración pública, órganos autónomos, entidades y empresas públicas y privadas.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                Al finalizar el curso, los participantes comprenderán ciclos de vida, el proceso metodológico de elaboración, aprobación, la gestión dinámica y sistemas de gestión de las cartas de servicio de las organizaciones de la administración pública, órganos autónomos, y entidades y empresas públicas y privadas.
              </p>
              <p>
                Asimismo, los participantes identificaran las formas de certificación de los compromisos en estas en las organizaciones de la administración pública, órganos autónomos, entidades y empresas públicas y privadas.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>La alta dirección, jefes, coordinadores y personal involucrado en la implantación y certificación de las cartas de servicio en las organizaciones de la administración pública, órganos autónomos, entidades y empresas públicas y privadas.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>
                    <b>Generación y gestión de las cartas de servicio</b>
                  </li>
                  <ul>
                    <li>Ciclo de vida de una carta de servicio</li> 
                    <li>Proceso de elaboración y aprobación de la carta de servicios.</li>
                    <li>Metodología:</li>
                    <ul>
                      <li>Análisis de situación y recolección de datos.</li>
                      <li>Evaluación interna</li>
                      <li>Redacción, aprobación y publicación de la carta de servicios</li>
                      <li>Comunicación</li>
                      <li>Formación</li>
                    </ul>
                    <li>Gestión dinámica de la carta de servicios</li>
                    <li>Sistemas de gestión con varias cartas de servicios</li>
                  </ul>
                  <li>
                    <b>Certificación de procesos</b>
                  </li>
                  <ul>
                    <li>Empresas certificadores en la Norma UNE 93200:2008</li>
                    <li>Introducción</li>
                    <li>Ideas básicas</li>
                    <li>Contexto general</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>2 días 3 horas (3 horas por día) 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>19 y 20 de junio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  