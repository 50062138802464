<template>
  <div class="container-fluid">
    <h2 class="my-h2">Servicio de implantación</h2>
    <div class="general-text">
      <p>
        <ol>
          <li>Como primer paso <b>integramos un Comité</b> dentro del Centro de Trabajo que será el encargado de la implementación de la Norma Oficial Mexicana NOM-035-STPS-2018 factores de riesgos Psicosociales en el trabajo-identificación, análisis y prevención. Éste debe estar integrado por los dueños y la alta dirección.</li>
          <li><b>Capacitar a la Alta Dirección e integrantes del Comité</b>: Una vez que se nombraron a los integrantes del comité, se sensibilizara a los dueños, alta dirección y miembros del comité sobre el objetivo de la norma, los problemas de raíz que busca resolver y el papel vital que juega cada uno de ellos para llevarla a buen término.</li>
          <li>Creamos e implantamos en el centro de trabajo una <b>política de prevención de riesgos psicosociales</b> para:</li>
          <ul>
            <li>Prevenir los factores de riesgo psicosocial.</li>
            <li>Prevenir la violencia laboral.</li>
            <li>Proporcionar un entorno organizacional favorable.</li>
          </ul>
          <li><b>Sensibilizar a toda la organización</b>. Se capacitará de manera clara a todo el personal de la organización, los colaboradores serán capaces de identificar a tiempo los riesgos a los que están expuestos y tomar las acciones necesarias con la confianza de que su centro de trabajo busca cobijarlos y brindarles el apoyo necesario.</li>
          <li><b>Difundir la política del centro de trabajo</b>. Te explicaremos las formas de poder promoverla y porque:</li>
          <ul>
            <li>La política debe estar en un lugar en donde toda la organización pueda consultarla.</li>
            <li>Debes tener evidencia de que realizaste un proceso de difusión. Esta evidencia puede incluir medios como folletos, boletines, carteles, correos, infografías, evidencia de platica (fotos o lista de asistencia).</li>
          </ul>
          <li><b>Identificar y analizar los factores de riesgo psicosocial</b>. Te mostraremos cuales son las herramientas que puedes emplear para llevar a cabo esta acción, y propondremos diseñaremos la que de acuerdo con el centro de trabajo sea la más idónea considerando para ello los siguientes factores:</li>
          <ul>
            <li>Exposición a acontecimientos traumáticos severos:</li>
            <ul>
              <li>Estrés laboral.</li>
              <li>Trastornos:</li>
              <ul>
                <li>De ansiedad.</li>
                <li>No orgánico del ciclo sueño-vigilia.</li>
                <li>De adaptación.</li>
              </ul>
            </ul>
            <li>Violencia laboral.</li>
            <li>Condiciones de ambiente de trabajo.</li>
            <li>Cargas de trabajo.</li>
            <li>Falta de control sobre el trabajo.</li>
            <li>Las jornadas de trabajo y rotación de turnos que exceden lo establecido en la Ley Federal del Trabajo.</li>
            <li>Interferencia en la relación trabajo-familia.</li>
            <li>Liderazgo y relaciones negativos en el trabajo.</li>
          </ul>
          <li><b>Evaluar el entorno laboral</b>. Se propondrá la herramienta para realizar esta actividad, a través de la cual se considere lo siguiente:</li>
          <ul>
            <li>El sentido de pertenencia de los trabajadores a la empresa.</li>
            <li>La formación para la adecuada realización de las tareas encomendadas.</li>
            <li>La definición precisa de responsabilidades para los trabajadores.</li>
            <li>La participación proactiva y comunicación entre el patrón, sus representantes y los trabajadores.</li>
            <li>Las relaciones sociales que se establecen dentro del trabajo.</li>
            <li>La distribución adecuada de cargas de trabajo, con jornadas laborales regulares.</li>
            <li>Capacitación e información que recibe el empleado.</li>
            <li>Reconocimiento al desempeño.</li>
          </ul>
          <li><b>Prevenir y tomar acción sobre los factores de riesgo detectados</b>. Se establecerá un programa de prevención y controles de los factores de riesgos psicosociales detectados en la organización.</li>
        </ol>
      </p>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "ServicioDeImplantacionView",
};
</script>
        
<style>
</style>