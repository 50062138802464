<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          El soborno, lubricante y engrudo
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">El soborno, lubricante y engrudo</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/dirty_money.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b
            >La peor forma de corrupción es la incompetencia. José Chanes
            Nieto</b
          >
        </p>
        <br />
        <p>
          El soborno es una práctica más común en la vida cotidiana de lo que
          nos imaginamos, pero contrariamente a esta afirmación,
          <b
            >la mayoría de la población está convencida de que cuando realiza
            actos de esta naturaleza no está sobornando</b
          >,
          <em
            >porque no lo identifica plenamente y no se identifica como una
            persona deshonesta</em
          >, por supuesto que tampoco lo considera un delito dado que existe la
          opinión generalizada de que solo es soborno cuando están en medio
          grandes cantidades de dinero.
        </p>
        <p>
          También existe la creencia de que el soborno es un ejercicio
          deshonesto de gobiernos y empresas, nunca del ciudadano de a pie, para
          acabar pronto, cuando nos toca dar un “entre” a un coyote para
          acelerar un trámite, lo calificamos como lubricante y engrudo de la
          vida diaria, como algo necesario, algo culturalmente aceptado,
          <em>nunca como un soborno</em>.
        </p>
        <p>
          El soborno también está presente en la vida laboral de todas las
          personas de manera más común de la que podríamos imaginar, solo
          recuerden lo que tuvieron que hacer cuando su jefe les puso una meta
          de trabajo alta, imposible de lograr, y les prometió que si la
          alcanzaban recibirían un aumento de sueldo o una gratificación
          adicional.
        </p>
        <p>
          Ante esta promesa, alcanzable a la opinión de su jefe, debemos
          preguntarnos quien o quienes lograron el objetivo mediante
          comportamientos éticos, y quienes lo alcanzaron “sobornando” con
          regalos, promesas, o tráfico de influencias a las personas (socios de
          trabajo), que tenían que colaborar para que se lograra el ansiado
          incremento salarial o la gratificación. <b>Eso es soborno también.</b>
        </p>
        <p>
          En 2019 fue presentado el “Barómetro Global de la Corrupción (BGC):
          América Latina y el Caribe”, elaborado por Transparencia
          Internacional; estudio en el cual México se colocó como el segundo
          país con más sobornos de Latinoamérica. De acuerdo con la
          investigación, la policía es la institución a la que se pagan más
          sobornos. También se documentó que tres de cada diez personas tuvieron
          que pagar algún soborno para obtener algún servicio público.
        </p>
        <p>
          En México, el 34% de los ciudadanos consultados reportó haber pagado
          un soborno durante ese año, lo que coloca al país en el índice general
          de soborno sólo por detrás de Venezuela, que alcanzó el 50% de
          recurrencia.
        </p>
        <p>
          El soborno en tiempos de elecciones representa un foco rojo para
          México,
          <b
            >ya que se ubicó como el país con más menciones de compra de votos
            de toda la región: 50%;</b
          >
          una de cada dos personas recibió un soborno a cambio de su voto, y una
          de cada cuatro personas fue amenazada con represalias.
        </p>
        <p>
          Acabar con prácticas como los sobornos, no se logra con discursos
          consistentes y bien estructurados, requiere acciones reales,
          tangibles; el Sistema de Gestión Antisoborno basado en la norma ISO
          37001, y todo el naciente modelo de Compliance son herramientas para
          lograrlo, ya que cuentan con la definición de políticas, objetivos de
          mejora y actividades definidas, regulan la responsabilidad
          administrativa y responsabilizan a las organizaciones (públicas,
          privadas y sociales) de cualquier posible delito de soborno iniciado
          en la organización.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>