<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Índice de corrupción
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Índice de corrupción</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/corruption.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          <b
            >DE 2011 a la fecha, México ha caído 24 lugares en el índice de
            Percepción de la Corrupción (IPC)
          </b>
        </p>
        <p>
          Existe una máxima en la gestión para resultados que plantea lo
          siguiente: “lo que se mide, se puede mejorar”. En Visión y Estreategia
          hemos completado esta frase diciendo “lo que no se mide, se
          deteriora”.
        </p>
        <p>
          La corrupción no se acabará nunca, siempre será un rumor en los
          pasillos que servirá muchas veces para desprestigiar personas,
          empresas e instituciones, sin embargo, si se debe controlar, por las
          graves consecuencias económicas que acarrea para la sociedad.
        </p>
        <p>
          Si queremos controlar su avance, deberíamos empezar por plantear
          mecanismos que permitan conocer cuál es el panorama de la situación en
          términos cuantitativos.
        </p>
        <p>
          ¿Es posible medir los actos de corrupción?, ante la complejidad -por
          no decir imposibilidad- de medir todos y cada uno de los casos,
          Transparencia Internacional ha desarrollado por más de 15 años el
          índice de Percepción de la Corrupción (IPC), un estudio que refleja
          cual es la sensación que tienen los habitantes sobre el grado de
          corrupción que existe en el sector público de cada uno de sus países,
          contemplando comportamientos como soborno, desvío de fondos públicos,
          uso de la función pública para beneficio personal, nepotismo en la
          administración pública y captura del Estado.
        </p>
        <p>
          Al ser un estudio anual permite conocer avances o retrocesos de los
          gobiernos globales. En la última edición IPC 2020 se clasificó a 180
          países, y centró su atención en el impacto que tuvo la corrupción en
          las respuestas gubernamentales al COVID-19, comparando el desempeño de
          los países en el índice con su inversión en atención médica y el grado
          en que las normas e instituciones democráticas se han debilitado
          durante la pandemia.
        </p>
        <p>
          Se estima que, en el caso de México, la corrupción puede significar el
          9% del Producto Interno Bruto, que se traduce en alrededor de 103 mil
          millones de dólares, lo que nos lleva a la pregunta ¿Cómo se encuentra
          México en el ranking de percepción de la corrupción 2020?, el índice
          elaborado por Transparencia Internacional ha mostrado un cambio
          significativo en el caso mexicano, pues pasó de la posición 105 en
          2012, a estar en el lugar 138 en el año 2018, siendo esta su peor
          posición en el ranking. Con el actual gobierno del presidente López
          Obrador, se ha notado un avance pues se logró pasar de ese 138 a la
          posición 124 de percepción de corrupción en 2020; cabe señalar que el
          mejor puesto de México se dio en el año 2011, año en el que logró
          ocupar el puesto número 100.
        </p>
        <p>
          Expertos de las Naciones Unidas señalan que los costos asociados a la
          corrupción ascienden a 2,6 billones de dólares al año, más del 5% del
          PIB mundial, pero la relevancia de este estudio no es solamente por el
          aspecto económico al que se ve ligado el tema de la corrupción, y
          tampoco para señalar que país es más o menos corrupto, sino que, en un
          sentido técnico y metodológico, permite establecer en dónde nos
          encontramos parados y definir las acciones críticas necesarias para
          encaminar, tanto la gestión gubernamental, como a las y los servidores
          públicos hacia prácticas libres de corrupción.
        </p>
        <p>
          Habrá que seguir muy de cerca este estudio, pues con los efectos
          económicos de la pandemia y la llegada de las vacunas, para la edición
          de 2021 se podrá consolidar la relación entre la gestión de la crisis
          y la percepción de la corrupción en los países.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>