<template>
  <div class="container-fluid">
    <h2 class="my-h2">Servicio de implantación</h2>
    <div class="general-text">
      <p>Asignamos a consultores(as) para trabajar en equipo, durante todas las visitas a su organización se prestará un servicio de asesoramiento enfocado en optimizar el resultado final.</p>
      <p>Adaptamos modelos a cada realidad de las circunstancias, tanto internas como externas de la organización.</p>
      <p>
        Etapas del proceso de implementación del SGAS:
        <ul>
          <li>Diagnóstico y planificación</li>
          <li>Diseño del sistema</li>
          <li>Implementación del sistema</li>
          <li>Diseño del tablero de control</li>
          <li>Auditoría interna</li>
          <li>Revisión por la Dirección</li>
          <li>Pre auditoría de tercera parte</li>
          <li>Acompañamiento en la Certificación</li>
        </ul>
      </p>
      <p>
        Ayudamos a las organizaciones de todo tipo y tamaño a gestionar el riesgo, con efectividad, previendo una serie de técnicas para la identificación y evaluación de riesgos para una mayor eficacia: estableciendo el contexto, valoración de riesgos, tratamiento de los mismos, en pro de resultados fiables de la organización.
        Además, establecemos un fuerte fundamento para la toma de decisiones, para alentar a la gestión proactiva en todas las áreas de la organización y en todas sus operaciones.
      </p>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "ServicioDeImplantacionView",
};
</script>
        
<style>
</style>