<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Formación de equipo auditor para la auditoría del
            sistema de gestión electoral
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Entender el papel de la auditoría interna en el sistema de gestión de calidad electoral, identificando la relevancia de las competencias del auditor interno en el proceso de manutención y mejora del sistema en mención, así como aplicar las técnicas para realizar una auditoría interna efectiva. 
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso está orientado a suministrar los elementos relacionados a la gestión de auditorías internas, basado en el estándar de la NMX-CC-54001-IMNC-2020 vigente, con el objeto de entregar el sustento técnico, para que el auditor en formación disponga de la capacidad y competencia de ejecutar auditorías efectivas. 
              </p>
              <p>
                Al finalizar la actividad los asistentes serán capaces de organizar, ejecutar y efectuar auditorías internas, contribuyendo a la mejora continua del sistema de gestión de calidad del organismo electoral.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Personas que deseen efectuar actividades de auditoría interna de sistemas de gestión de calidad electoral, tales como: de la alta dirección o servidores electorales encargados en la materia, que deseen realizar auditorías de primera parte. 
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                Tema 1. El papel del proceso de auditoría
                <ol>
                  <li>Fines de la auditoría;</li>
                  <li>Beneficios y limitaciones de la auditoría;</li>
                  <li>El rol de las auditorías internas y el esquema de certificación;</li>
                  <li>Referencias normativas;</li>
                  <li>Principios de la auditoría;</li>
                  <li>La actividad debida; y</li>
                  <li>Preparación de la lista de verificación.</li>
                </ol>
              </p>
              <p>
                Tema 2. Competencia de los auditores
                <ol>
                  <li>Particularidades personales;</li>
                  <li>El lenguaje del cuerpo;</li>
                  <li>Perfil profesional; y</li>
                  <li>Formación como auditor.</li>
                </ol>
              </p>
              <p>
                Tema 3. Planificación y ejecución de la auditoría
                <ol>
                    <li>El esquema de auditorías de sistemas de Gestión de Calidad electoral;</li>
                    <li>Modelos de verificación. Criterios para el cumplimiento;</li>
                    <li>Programación de auditoría: alcances, fecha (s) auditor;</li> 
                    <li>Ejecución de la auditoría: reunión de inicio, obtención de evidencias, y reunión de termino;</li>
                    <li>Técnicas para obtener evidencias y determinación de no conformidades;</li>
                    <li>Análisis de la causa raíz; y</li>
                    <li>Autoevaluación.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>5 días 20 horas
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 14 al 18 de agosto de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  