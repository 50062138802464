<template>
  <div class="container-fluid">
    <h2 class="my-h2">Preguntas frecuentes</h2>
    <div class="general-text">
      <p>
        <b>¿Cuál es el propósito de la gestión de riesgos?</b>
      </p>
      <p>
        Es la creación y la protección del valor. Mejora el desempeño, fomenta
        la innovación y contribuye al logro de objetivos.
      </p>
    </div>
    <div class="general-text">
      <b
        >¿Cuáles con los principios que proporcionan a una organización
        orientación sobre las características de una gestión del riesgo eficaz y
        eficiente, comunicando su valor y explicando su intención y
        propósito?</b
      >
    </div>
    <div class="center">
      <img
      src="@/assets/img/creacion_y_proteccion_del_valor.png"
      alt=""
      class="center img-fluid"
    />
    </div>
    <div class="general-text">
      <p>
        <b>¿Cuáles son los beneficios de la implementación de un plan de Gestión de Riesgo?</b>
      </p>
      <p>
        <ul>
          <li>La organización aumenta sus probabilidades de alcanzar las metas propuestas.</li>
          <li>Permite el cumplimiento de requisitos legales en varias áreas.</li>
          <li>Mejora el conocimiento en administración.</li>
          <li>Protege los recursos de la organización.</li>
          <li>Aumenta la eficacia y la eficiencia operativa de la organización.</li>
          <li>Establece una base de datos confiable para la toma de decisiones.</li>
          <li>Hace que la Alta Dirección de la organización, sea consciente de la importancia de controlar e identificar y gestionar los riesgos.</li>
        </ul>
      </p>
    </div>
  </div>
</template>
        
<script>
export default {
  name: "TransparenciaView",
};
</script>
        
<style>
</style>