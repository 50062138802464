<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Teletrabajar después de la pandemia
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Teletrabajar después de la pandemia</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/telecommuting.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align:center">
                <b>El secreto del éxito en los negocios está en detectar hacia dónde va el mundo y llegar ahí primero. Bill Gates</b>
              </p>
              <br/>
              <p>
                La pandemia nos obligó a teletrabajar, ahí aprendimos que en casa no existe un horario para trabajar, pues se precisa atender también labores domésticas, aprendimos que nadie respetaba los horarios de desconexión, y también aprendimos que el trabajo se exigía como si tuviéramos todos los recursos disponibles. Todos estos factores ocasionaron problemas laborales y también mentales de las personas que teletrabajaron.
              </p>
              <p>
                Ante la posibilidad de que se presente otra pandemia, el 15 de julio del presente año, se publicó en el Diario Oficial de la Federación la <b>Norma Oficial Mexicana NOM-037-STPS-2022, Teletrabajo-Condiciones de seguridad y salud en el trabajo</b>, elaborada con el objetivo de establecer condiciones de prevención para evitar riesgos físicos, ergonómicos y psicosociales en la materia.
              </p>
              <p>
                El teletrabajo es una forma de trabajo que se realiza a distancia, en una ubicación alejada de una oficina central o de las instalaciones de producción, con la ayuda de las TIC —Tecnologías de la Información y la Comunicación— que facilitan al mismo tiempo la separación física y la comunicación.
              </p>
              <p>
                En México cerca de 13 millones de personas están en posibilidades de teletrabajar, así que a la par de los beneficios reales que se obtienen (mayor conciliación laboral, reducción de costos empresariales, e incluso, aumento de la productividad), existen también riesgos que pueden afectar la salud de las personas trabajadoras, y que debido a encontrarse fuera del centro de trabajo requieren una protección especial.
              </p>
              <p>
                Para esto la <b>NOM 037</b> contempla las condiciones elementales y las medidas específicas de prevención que deberán adoptarse en los lugares de trabajo que determinen de común acuerdo los patrones y las personas teletrabajadoras, ello con el fin de prevenir eventos que pongan en peligro la integridad física, y la salud, a través de una normatividad en materia de seguridad y salud en el trabajo acorde con los avances tecnológicos, y con enfoque preventivo de las enfermedades laborales y accidentes de trabajo.
              </p>
              <p>
                Un primer listado de requisitos para teletrabajar:
                <ul>
                  <li>El CT debe mantener un listado actualizado de las personas teletrabajadoras con su domicilio y el lugar o lugares donde se realizarán las actividades de teletrabajo.</li>
                  <li>Comprobar que dichos lugares cumplan con las condiciones específicas previstas en la NOM 037.</li>
                  <li>Establecer, implantar, mantener y difundir en el centro de trabajo y entre las personas teletrabajadoras una política de teletrabajo: propiciando medios y horarios de comunicación, pero sobre todo que fomente la importancia de la salud del teletrabajador.</li>
                  <li>Mantener informado al teletrabajador de los riesgos relacionados con la actividad que desarrollen, y de la posible exposición de agentes y factores de riesgo, tanto ergonómicos como psicosociales. </li>
                  <li>Mantener un acuerdo escrito para que la o el trabajador mantenga el área de trabajo limpia y ordenada, así como de mobiliario ergonómico en pro de su bienestar.</li>
                </ul>
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>