<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Inducción a la gestión de las cartas de servicio
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>
                    Explicar los productos que ofrecen las cartas de servicio a las organizaciones de la administración pública, organismos autónomos y entidades y empresas públicas y privadas, así como los compromisos de calidad que asumen como un instrumento por medio del cual se informa a los usuarios o clientes referente a los servicios que prestan y los compromisos de calidad que adquieren en su prestación.
                  </li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                Este curso introducirá a los asistentes respecto del comienzo y el perfeccionamiento de las cartas de servicio en nuestro medio mediante la ejemplificación de algunas de estas, así como dando a conocer algunas experiencias en el ámbito internacional y nacional sobre las mismas, a partir de lo establecido en la Norma UNE 93200:2008.  
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Personas que tengan a cargo la responsabilidad de implementar las cartas de servicios en las organizaciones de la administración pública, organismos autónomos y entidades y empresas públicas y privadas, así como personal que esté involucrado en la creación de las cartas, y personal que considere las organizaciones, organismos, entidades y empresas públicas, privadas y autónomas.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>
                    <b>1.	El origen de las cartas de servicio</b>
                  </li>
                  <ul>
                    <li>Las cartas de servicio en nuestro entorno: algunos ejemplos</li>
                    <li>Experiencias en el ámbito internacional.</li>
                    <li>Experiencias en el ámbito nacional</li>
                  </ul>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>1 día, 2 horas
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>2 de junio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  