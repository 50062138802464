<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Implementador(a) líder en la norma ISO 37001:2016
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>Comprender los elementos y las operaciones de un Sistema de Gestión Antisoborno y sus principales procesos.</li>
                  <li>Reconocer la correlación entre la norma ISO 37001 y otras normas y marcos regulatorios.</li>
                  <li>Comprender los enfoques, métodos y técnicas utilizados para la implementación y la gestión de un sistema de Gestión Anticorrupción.</li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                La capacitación permitirá a los asistentes aprender los elementos básicos para implementar y formalizar un Sistema de Gestión Anticorrupción, según lo especificado en la ISO 37001. 
              </p>
              <p>
                Durante el desarrollo del curso, los participantes serán capaces de comprender los diferentes módulos del Sistema de Gestión Antisoborno, entre los que se encuentran las políticas, los procedimientos, las medidas de desempeño, el compromiso de la dirección, auditoría interna, revisión por la dirección y mejora continua del Sistema.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Las personas preocupadas por la Gestión Antisoborno, las que desean adquirir conocimientos respecto de los procesos de los Sistemas de Gestión Antisoborno, ya las interesadas en seguir una carrera de gestión antisoborno.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Planificar la implementación de un Sistema de Gestión Antisoborno.</li>
                  <li>Implementación de un Sistema de Gestión Antisoborno.</li>
                  <li>Seguimiento, medición, mejora continua y preparación para una auditoría de certificación de un sistema de Gestión Antisoborno.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>4 días 12 horas (3 horas por día). 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 16 al 19 de mayo de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  