<template>
  <div class="container-fluid">
    <h1 class="my-h1">Gestión de la Innovación</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Gestión de la Innovación</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        <b
          >“Innovación es hablar de futuro; nuevas formas de hacer las cosas
          para detonar resultados”</b
        >
      </p>
      <p>
        Cambia la forma de cumplir con las expectativas de la organización;
        seguir con una usual rutina impide obtener éxito del servicio que se
        brinda. Modifica y perfecciona la rutina del proceso habitual a una
        rutina inédita y original, ayuda a tu organización a generar nuevas
        ideas para aumentar la satisfacción de servicios y/ productos.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Ofrecer un servicio implica satisfacer al usuario, los métodos tradicionales ya no son suficientes y deseados por la sociedad, es necesario estar en tendencia con las maneras de brindar servicios.
      </p>
      <p>
        Descubre como transformar el proceso de la organización; ofrecer un mejor servicio necesita de ideas, generación de técnicas, creación de valor e impacto. Las organizaciones que son competitivas adaptan cambios en su personal y a los procesos de implementación de sus servicios; respondiendo a necesidades de los usuarios. La innovación es una herramienta de competitividad que hace diferente el servicio al de la competencia.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>          
          <li>Formas de organización (cómo empezar a innovar).</li>
          <li>Identificación de ideas.</li>
          <li>Diseño y formulación de estrategias de innovación.</li>
          <li>Herramientas de la innovación para las organizaciones.</li>
          <li>Modelos y tendencias actuales para la gestión de la innovación.</li>
          <li>Herramientas para gestionar la innovación en las organizaciones.</li>
          <li>Características de un proyecto innovador.</li>
          <li>Soluciones de alto impacto aterrizadas y medibles.</li>
          <li>Gestión del portafolio de innovación permanente.</li>
          <li>Creación de una cultura de innovación.</li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>