<template>
  <div class="container-fluid">
    <h1 class="my-h1">Gestión por Procesos</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Gestión por Procesos</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        Una organización alcanza la mayor eficiencia cuando el conjunto de sus actividades se gestiona como sistema de procesos. Es preciso que la organización sea capaz de identificar, diseñar, medir y mejorar el proceso de gestión.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        La gestión por procesos, es la piedra angular de la organización que aspire a un crecimiento en base a principios de calidad, enfoque al cliente y productividad, propone un cambio de actitud en todos los miembros y ayuda de manera significativa en la mejora en todos los ámbitos de gestión en las organizaciones.
      </p>
      <p>
        Las organizaciones conocen sus actividades, pero les cuesta identificar sus procesos, o tienen dificultades en el proceso a la hora de gestionarlos. Es conveniente en toda organización que las actividades que proporcionan valor y utilizan recursos se desarrollen como procesos. El éxito de la gestión está directamente vinculado con la gestión sistemática de los procesos y sus interacciones.
      </p>
      <p>
        Conoce como medir y mejorar los procesos, tendrás una orientación para establecer valor agregado, obtener resultados determinados mediante evidencia objetiva y mejorar continuamente los resultados.
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Fundamentos sobre la Gestión por Procesos.
          </li>
          <li>
            El enfoque basado en procesos.
          </li>
          <li>
            El mapa de procesos.
          </li>
          <li>
            Diseño y Representación de Procesos.
          </li>
          <li>
            Beneficios del Mapeo de Proceso.
          </li>
          <li>
            SIPOC.
          </li>
          <li>
            Diagrama de flujo.
          </li>
          <li>
            La mejora de procesos.
          </li>
          <li>
            Requisitos para mejorar los procesos.
          </li>
          <li>
            Metodología para la Determinación de Procesos.
          </li>
          <li>
            Metodologías para el Seguimiento, Medición y
            Análisis de Procesos.
          </li>
          <li>
            Metodología para la Mejora de Procesos.
          </li>
          <li>
            Herramientas para la Mejora de Procesos y Sistemas
            de Gestión de Calidad.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>