<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Desarrollo de la documentación del Sistema de
            Gestión Electoral
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                Al finalizar el curso los participantes podrán desarrollar la documentación de la organización de una manera eficaz y conforme norma y obtendrán las bases para aplicar los criterios de control en documentos, como en los registros que se genera en un sistema de gestión de la calidad.
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                En este curso se abordan todos los aspectos necesarios para el desarrollo de documentos dentro de una organización, y conforme a los lineamientos y estatutos de la norma, logrando que los participantes obtengan el conocimiento respecto del control en la generación, registro y archivo, así como la creación de instructivos y planes de calidad.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Aquellas personas que van a ser o son responsables del desarrollo e implementación del sistema, auditores, futuros auditores, comités de calidad y directivos. 
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ol>
                  <li>Generalidades;</li>
                  <li>Niveles de los documentos del sistema de calidad;</li>
                  <li>Política y objetivos de calidad;</li>
                  <li>Manual de calidad;</li>
                  <li>Procedimientos;</li>
                  <li>Instructivos;</li>
                  <li>Registros;</li>
                  <li>Documentos necesarios para la organización para asegurar la eficaz planificación, operatividad y control de sus procesos;</li>
                  <li>Planes de calidad; y</li>
                  <li>Controles para los documentos del sistema.</li>
                </ol>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>1 día 5 horas
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>8 de agosto de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  