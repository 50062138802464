<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Cartas de servicios, gestión por resultados y comunicación efectiva
          con los usuarios
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Cartas de servicios, gestión por resultados y comunicación efectiva con
      los usuarios
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/documentos.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <em>
            “La diferencia entre la participación y el compromiso es como el
            jamón y los huevos. El pollo está involucrado; el cerdo está
            comprometido”. Martina Navratilova
          </em>
        </p>
        <br />
        <p>
          Las cartas de servicios están revolucionando la prestación de los
          trámites en el sector público y la forma como se trata a los usuarios
          de los servicios en la empresa.
        </p>
        <p>
          Con esta metodología, vigente desde el año 2008 a través de la Norma
          UNE 93200, es posible dialogar con los usuarios para convenir la mejor
          forma de relacionarse. Por primera quien está detrás del escritorio
          salió a preguntar directamente a quienes están en la fila de espera,
          si se le está atendiendo bien. ¡Eso es fantástico!
        </p>
        <p>
          Ha sido un largo camino para encontrar una metodología que garantice
          que las áreas de atención al público no solo participen en la
          prestación de los trámites, se logró al fin el anhelado c o m p r o m
          i s o .
        </p>
        <p>
          No fue fácil, ya en 1991 en Gran Bretaña se elaboró el programa Carta
          del Ciudadano, que buscaba estandarizar la prestación de los
          servicios, crear competencia, fomentar la participación ciudadana en
          su diseño, informar sobre los procedimientos, iniciar una etapa de
          mejora continua y ofrecer igualdad de respuesta para todos.
        </p>
        <p>
          A partir de esta experiencia exitosa se elaboraron Cartas del
          Ciudadano en Francia, Bélgica, Portugal, Italia y Dinamarca; hasta que
          en 2008 se desarrolló la Norma UNE 93200 que especifica los requisitos
          fundamentales que se deben seguir para desarrollar Cartas de
          Servicios.
        </p>
        <p>
          En todas partes la ciudadanía tiene necesidades concretas y
          expectativas que han de ser satisfechas por sus gobiernos, para
          conocer y responder mejor a sus demandas.
        </p>
        <p>
          También las empresas en la mayoría de los países vienen utilizando
          cartas que les permiten manifestar públicamente la materialización de
          los principios de transparencia, participación, responsabilidad y
          compromiso en la prestación de los servicios.
        </p>
        <p>
          Este instrumento -<b><em>Carta de Servicios</em></b
          >-; es una herramienta de gestión para mejorar la calidad de los
          servicios en consonancia con las necesidades de los ciudadanos.
          Facilitan la relación con los usuarios y sirven para definir
          estándares de servicio, permitiendo establecer objetivos ambiciosos
          que logren el mayor impacto posible en los usuarios. Mediante la carta
          se informa a la ciudadanía sobre los servicios que tienen encomendados
          y difunden los compromisos de calidad de estos servicios y los
          derechos de los usuarios en relación a ellos.
        </p>
        <p>
          El desarrollo de las cartas permite que el servicio prestado sea
          monitoreado y que siempre sea evaluado por los usuarios, ya sea
          internamente a través de indicadores en constante verificación para
          cumplir con el compromiso asumido, y externamente los usuarios tienen
          derecho a quejarse y/o reclamar la forma en que se recibe el servicio
          cuando existan percances.
        </p>
        <p>
          Asumir responsabilidad y compromiso en la prestación de los servicios,
          es el inicio de un gran trabajo, establecer indicadores de desempeño y
          seguimiento, escuchar la voz del usuario, orientar la organización a
          las personas, simplificar los procesos, contar con medidas de
          subsanación y compensación, enmarcan a una administración de
          excelencia.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>