<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          ¿Qué alcances tiene un Sistema de Gestión Antisoborno?
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">¿Qué alcances tiene un Sistema de Gestión Antisoborno?</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/decline.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
                Soborno: Dar dinero o regalos a alguien para conseguir algo de forma ilícita. (Definición RAE)
              </p>
              <p>
                ISO 37001:2016 es la norma internacional de Sistemas de Gestión Antisoborno (SGAS), para su integración se tomó como base la Norma BS 10500: Anticorrupción y Ética empresarial, desarrollada por el British Standard Institute (BSI). Proporciona los requisitos, implementar, mantener y mejorar un sistema de gestión antisoborno. 
              </p>
              <p>
                Toda organización que ponga en marcha su SGAS debe establecer, documentar, implementar, mantener y revisar continuamente sus procesos de trabajo, diseñando medidas para identificar y evaluar el riesgo, y para prevenir, detectar y enfrentar el soborno.
              </p>
              <p>
                Sus requisitos pueden aplicarse a cualquier organización sin importar el tamaño, giro, sector o si es privada, pública u organización civil, de igual manera para entidades gubernamentales. Es una norma con Estructura de Alto Nivel establecida por ISO para los sistemas de gestión, lo que significa que puede integrarse fácilmente con otros sistemas de gestión como 9001, si se tiene un Sistema de Gestión de Calidad, por ejemplo.
              </p>
              <p>
                Los requisitos especificados por ISO 37001 abordan dos áreas clave: 
                <ol>
                  <li>
                    Soborno por la organización, su personal o socios comerciales para su propio beneficio; y 
                  </li>
                  <li>
                    Soborno de la organización, su personal o socios comerciales en relación con sus actividades.
                  </li>
                </ol>
              </p>
              <p>
                Un aspecto destacable de la Norma, es el alcance que establece sobre aspectos novedosos de la organización donde se puede dar el soborno, a esto le llamaremos sus “dominios”.
              </p>
              <p>
                Este nombre se refiere a los alcances mínimos del Sistema, los cuales señalo enseguida:
              </p>
              <p>
                <em>Compliance</em>: Es el cumplimiento normativo, es decir, todo aquello que la organización está obligada a cumplir legalmente, aunque la RAE no lo identifica como tal, actualmente los profesionales de la materia lo utilizan usualmente para hacer referencia a esta acción.
              </p>
              <p>
                <em>Cohecho</em>: Es un delito que implica la entrega de un soborno para corromper a alguien y obtener un favor de su parte. Lo habitual es que esta dádiva, que puede concretarse con dinero, regalos, etc., sea entregada a un funcionario público para que éste concrete u omita una acción. 
              </p>
              <p>
                <em>Debida Diligencia</em>: Se emplea para conceptos que impliquen la investigación de una empresa o persona previa a la firma de un contrato o una ley con cierta diligencia de cuidado.
              </p>
              <p>
                <em>Conflicto de Intereses</em>: Es la situación donde los intereses de negocios, financieros, familiares, políticos o personales podrían interferir con el juicio de valor de las personas en el desempeño de sus obligaciones hacia la organización.
              </p>
              <p>
                La importancia de esta Norma para nuestro país radica en que es capaz de contener las prácticas corruptas que se han multiplicado en los últimos años, que se traducen en una posición bastante desventajosa para hacer negocios y lograr crecimiento económico.
              </p>
              <p>
                De acuerdo con el Índice de Percepción de la Corrupción de la organización de la sociedad civil Transparencia Internacional, se señala que Dinamarca es el país menos corrupto del mundo situándose en primer lugar de la clasificación, mientras que nuestro país ocupa el lugar 130 de 180 países, por debajo de muchos países de América Latina, con menor grado de desarrollo económico.
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>