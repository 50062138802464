<template>
  <div class="container-fluid">
    <h1 class="my-h1">Planeación Avanzada de la Calidad</h1>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">
            Inicio
          </router-link>
        </li>       
        <li class="breadcrumb-item">
          <router-link class="" to="/cursos">
            Cursos
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Planeación Avanzada de la Calidad</li>
      </ol>
    </nav>
    <div class="general-text">
      <p>
        El objetivo de una planificación avanzada de calidad de un producto es facilitar la comunicación con todos los involucrados para lograr un diseño y proceso sin fallas, incrementar la productividad y mantener la calidad esperada por el cliente.
      </p>
    </div>
    <h2 class="my-h2">¿Por qué este curso?</h2>
    <div class="general-text">
      <p>
        Para garantizar los lanzamientos de nuevos productos en forma exitosa evitando retrasos y retrabajos en la implementación.
      </p>
      <p>
        <ul>
          <li>
            Planear y definir un programa.
          </li>
          <li>
            Diseñar y desarrollar el producto.
          </li>
          <li>
            Diseñar y desarrollar el proceso.
          </li>
          <li>
            Validar el producto y proceso.
          </li>
          <li>
            Fabricar el producto y retroalimentar.
          </li>
          <li>
            Evaluación, retroalimentación y acciones
            correctivas.
          </li>
        </ul>
      </p>
    </div>
    <h2 class="my-h2">Temas abordar:</h2>
    <div class="general-text">
      <p>
        <ul>
          <li>
            Descripción, fines, y propósitos de la planeación
            avanzada de calidad.
          </li>
          <li>
            Matriz de responsabilidades.
          </li>
          <li>
            Organización del equipo.
          </li>
          <li>
            Definición del alcance.
          </li>
          <li>
            Planes de control.
          </li>
          <li>
            Análisis del modo y efecto de fallos.
          </li>
          <li>
            Herramientas causa efecto.
          </li>
          <li>
            Sistema de análisis de medición.
          </li>
          <li>
            Prueba de errores.
          </li>
          <li>
            Etapas en el ciclo de los proyectos.
          </li>
          <li>
            Análisis costo-beneficio.
          </li>
          <li>
            Evaluación social de proyectos.
          </li>
        </ul>
      </p>
    </div>
  </div>
</template>
          
<script>
export default {
  name: "CursosView",
};
</script>
          
<style>
</style>