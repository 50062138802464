<template>
  <div class="footer-text">
    <div class="container-fluid py-3">
      <footer>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3 center" style="text-align: center">
            <i class="fa fa-envelope-o"></i>
            <br />
            <a
              href="mailto: informes@visionyestrategia.com.mx"
              class="anchor-footer-text"
              >informes@visionyestrategia.com.mx</a
            >
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 center" style="text-align: center">
            <i class="fa fa-building-o"></i>            
            722-544-7945 Ext. 104
            <br />
            <i class="fa fa-building-o"></i>            
            722-544-7945 Ext. 106
            <br />
            <a href="tel:722-267-6413" class="anchor-footer-text">
              <i class="fa fa-volume-control-phone"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=7222676413"
              class="anchor-footer-text"
              target="_blank"
            >
              <i class="fa fa-whatsapp"></i>
            </a>
            722-267-6413
            <br />
            <a href="tel:722-387-5761" class="anchor-footer-text">
              <i class="fa fa-volume-control-phone"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=7223875761"
              class="anchor-footer-text"
              target="_blank"
            >
              <i class="fa fa-whatsapp"></i>
            </a>
            722-387-5761
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 center" style="text-align: center">
            <i class="fa fa-map-marker"></i>
            <br />
            <a
              href="https://goo.gl/maps/MWneWd57QfmDY1tY7"
              class="anchor-footer-text"
              target="_blank"
              >Ex Hacienda Barbabosa 100-A Col. Barbabosa, Zinacantepec C.P.
              51350</a
            >
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 center" style="text-align: center">
            <i class="fa fa-clock-o"></i>
            <br />
            9:00 a.m. - 6:00 p.m.
          </div>
          <div class="w-100"></div>
          <div class="col-12 center">
            Mantente en Contacto
            <a
              href="https://www.linkedin.com/company/visi%C3%B3n-y-estrategia/"
              class="anchor-footer-text"
              target="_blank"
            >
              <i class="fa fa-linkedin-square"></i>
            </a>
            <a
              href="https://www.facebook.com/VyEstrategia"
              class="anchor-footer-text"
              target="_blank"
            >
              <i class="fa fa-facebook-square"></i>
            </a>
            <a
              href="https://twitter.com/VyEstrategia?t=jgLuZ512xe8xGQBCG8Y6PQ&s=09"
              class="anchor-footer-text"
              target="_blank"
            >
              <i class="fa fa-twitter-square"></i>
            </a>
            <a
              href="https://www.youtube.com/@vision_y_estrategia"
              class="anchor-footer-text"
              target="_blank"
            >
              <i class="fa fa-youtube-square"></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
  <div class="container-fluid derechos-reservados">
    <div class="container">
      <div class="row">
        <div class="col-sm-6" style="text-align: start">
          Todos los derechos reservados.
        </div>
        <div class="col-sm-6" style="text-align: end">
          <!--aviso de privacidad-->
          <a
            href="/pdf/AVISO DE PRIVACIDAD Y CONFIDENCIALIDAD.pdf"
            download
            class="download-footer"
            >Aviso de privacidad y confidencialidad.</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFooter",
};
</script>

<style>
</style>