<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          El fraude también es corrupción
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">El fraude también es corrupción.</h1>
    <div class="d-flex justify-content-end">
      <b>Ramón Cuevas Martínez</b>
    </div>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/fraude.jpg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          La Estafa Maestra, sumada de los niveles históricos de los indicadores internacionales que dicen que cada año somos un país más corrupto, dieron origen al nacimiento del Sistema Nacional Anticorrupción (SNA).
        </p>
        <p>
          El problema de la concepción original del Sistema Anticorrupción es que solo le permite enfocarse (así lo percibo) a evitar que se vuelvan a presentar casos como La Estafa Maestra (EPN) o Segalmex (AMLO), cuando vemos que estos casos solo se dan de a uno por sexenio.
        </p>
        <p>
          Otra debilidad para que el sistema funcione es la propia sociedad mexicana, ya que las personas en general opinan que solo el gobierno es corrupto, e interpretan que, si ellos ofrecen dinero a un servidor público para facilitar un trámite, eso no es corrupción.
        </p>
        <p>
          El problema se resume en que la corrupción que importa de acuerdo con el diseño del SNA es la de los grandes desfalcos, y por otra parte a las personas no les importa seguir dando “mordida” para agilizar sus trámites.
        </p>
        <p>
          Esa situación abre un boquete en las medidas que se toman para combatirla, sobre todo tomando en cuenta que al menos hay 10 mecanismos fraudulentos que son altamente corruptos y que no cuentan con acciones claras para combatirlos.
        </p>
        <p>
          <em>
            ¿Por qué el fraude es corrupción y además está en todas partes del sector público?
          </em>
        </p>
        <p>
          El fraude abarca cualquier delito para generar ganancia que utiliza el engaño; es decir, es una declaración falsa a sabiendas de la verdad. <b>Todas las actividades fraudulentas son ilegales, y las personas involucradas en ellas son catalogadas como delincuentes.</b>
        </p>
        <p>
          <b>El fraude es la nueva corrupción</b>, con casos menos escandalosos y que involucran menos dinero en una sola operación, pero que están efectuándose diariamente.
        </p>
        <p>
          El primer tipo de fraude del que hablaré es el de…
        </p>
        <ul class="ul-clean">
          <li>
            <b>1.	Contrataciones que urgen</b>
          </li>
        </ul>
        <p>
          En las compras públicas existe una figura que permite bajo diferentes argumentos como premura, seguridad, o salud, adquirir cosas sin mediar licitaciones públicas. El problema es que estos mecanismos no son de uso, son de abuso.
        </p>
        <p>
          Vemos que se trata de un engaño en primer lugar porque los productos no son exclusivos de un solo proveedor, es decir existen en el mercado; por otro lado, los tiempos en los que se entregan prácticamente son los mismos que si habría existido licitación pública. En resumen, las compras de emergencia en general son procesos fraudulentos.
        </p>
        <p>
          Una investigación del IMCO encontró por ejemplo el abuso de las excepciones previstas en la Ley de Adquisiciones dado que el 71% de los contratos obtenidos por las mil empresas más ganadoras de concursos públicos fueron otorgados de manera directa entre el 2012 y el 2017.
        </p>
        <p>
          La siguiente estrega hablaré del mecanismo fraudulento que es “compromisos de gobierno y de campaña que no se cumplen”.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElFraude",
};
</script>    
<style>
</style>