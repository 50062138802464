<template>
  <div class="container-fluid">
    
    <div class="col-12 table-title">
              Nombre del curso: Pensamiento basado en riesgos con base en la Norma ISO 31000:2018, Sistema de Gestión de Riesgos
            </div>
            <div class="table-white">
              <b>Objetivo:</b>
              <p>
                <ul>
                  <li>Proveer a los asistentes conocimientos, habilidades y la experiencia práctica necesarias para lograr la mejora de los procesos teniendo en cuenta el pensamiento basado en riesgos.</li>
                  <li>Reconocer la ventaja de trabajar bajo el enfoque de procesos para propiciar la resiliencia organizacional.</li>
                  <li>Contar con herramientas para el análisis e impacto del negocio.</li>
                  <li>Profundizar en el efecto de una adecuada gestión de riesgos para el éxito de las organizaciones.</li>
                </ul>
              </p>
            </div>
            <div class="table-blue">
              <b>Descripción:</b>
              <p>
                El curso permitirá identificar los elementos problemáticos internos y externos que perjudican a los procesos de las organizaciones y por tanto, a los servicios/productos prestados. Consentirá que los participantes vena los procesos desde otra perspectiva, a mejorarlos pensando en posibles riesgos que puedan afectar los diferentes procesos de la organización.
              </p>
            </div>
            <div class="table-white">
              <b>Características:</b>
              <p>
                <b>Dirigido a: </b>Profesionales encargados de la gestión de procesos, sistemas e gestión y la excelencia empresarial que deseen o precisen mejorar sus conocimientos y habilidades de forma que puedan optimizar los procesos de sus organizaciones y reducir los riesgos. Sus principales destinatarios son:  Responsables de gestión de la continuidad de la organización, Técnicos de gestión de riesgos corporativos, auditores internos, responsables de procesos, personal técnico, profesionales relacionados con la gestión de riesgos corporativos.
              </p>
              <p>
                <b>Garantía de satisfacción: </b>Avalamos la transmisión de conocimientos a través de una metodología que permita elegir la información para el diseño de productos o resultados.
              </p>
              <p>
                <b>Constancia: </b>Al término del curso se otorgará una constancia de participación con valor curricular, con la insignia de Visión y Estrategia Consultoría.
              </p>
            </div>
            <div class="table-blue">
              <b>Temario:</b>
              <p>
                <ul class="ul-clean">
                  <li>
                    <b>Tema 1. </b>¿Por qué repensar o reconstruir procesos?                                
                  </li>
                  <li>
                    <b>Tema 2. </b>Proceso de análisis de impacto de la organización.
                  </li>
                  <li>
                    <b>Tema 3. </b>Proceso de identificación de peligros y evaluación de riesgos.
                  </li>
                </ul>
              </p>
            </div>
            <div class="table-white">
              <b>Horas de duración: </b>12 horas. 4 días (3 horas por día) 
            </div>
            <div class="table-blue">
              <b>Fecha de realización: </b>Del 26 al 29 de junio de 2023
            </div>
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  